.manage-list-content-renderer {
  width: 75%;
  min-height: 638px;
  /* overflow:auto;  */

  padding: 25px 40px;
  background-color: white;
  border-radius: 0px 8px 8px 0px;
}

.manage-vault-heading {
  font-size: 36px;
  font-family: Catamaran;
  color: #164f58;
}
.manage-list-main-div {
  display: flex;
  width: 100%;
  margin-top: 7px;
  box-shadow: 4px 4px 30px rgba(27, 62, 105, 0.16);
  border-radius: 8px 8px 8px 8px;
}

.manage-padding-and-width {
  padding: 15px 0px 0px 30px;
  width: 100%;
}

.edit-page-render {
  width: 100%;
  padding: 10px;
  border-radius: 0px 8px 8px 0px;
}

.admin-table {
  margin-top: 15px;
}

.admin-table td {
  padding: 7px 0;
  color: #164f58;
  letter-spacing: 0.3px;
  /* font-size: 14px; */
  font-family: "Open Sans", sans-serif;
}

.admin-table tr td:first-child,
.table-subheading {
  font-weight: bold;
  color: #164f58;
}

.table-subheading {
  margin-top: 10px;
  font-size: 15px;
}
.whitelist-table {
  margin: 20px 0 30px;
}

.admin-table img {
  height: 30px;
}

.whitelist-table th {
  /* font-size: 14px; */
  font-weight: bold;
  color: #6d7681;
  padding: 0 0 5px;
  font-family: "Open Sans", sans-serif;
}

.whitelist-table td {
  height: 30px;
  padding: 7px 0;
  /* font-size: 14px; */
  font-family: "Open Sans";
}
/* padding:"15px 0px 0px 30px" */

/* @media only screen and (max-width: 1560px) {
  .investments_and_vault_stats {
    width: 285px;
  }
}

@media only screen and (max-width: 1280px) {
  .investments_and_vault_stats {
  width:295px;
  }
} */

@media only screen and (max-width: 1720px) {
  .manage-padding-and-width {
    width: 63.91vw;
  }
}

@media only screen and (max-width: 1641px) {
  .manage-vault-heading {
    font-size: 30px;
  }
}

@media only screen and (max-width: 1618px) {
  /* .manage-padding-and-width {
    width: 61.91vw;
  } */
}

@media only screen and (max-width: 1516px) {
  /* .manage-padding-and-width {
    width: 59.91vw;
  } */

  .investments_and_vault_stats {
    width: 265px;
  }
}

@media only screen and (max-width: 1446px) {
  /* .manage-padding-and-width {
    width: 57.91vw;
  } */
  .investments_and_vault_stats {
    width: 265px;
  }
}

@media only screen and (max-width: 1380px) {
  /* .manage-padding-and-width {
    width: 55vw;
    padding-left: 15px;
  } */
}

@media only screen and (max-width: 1279px) {
  /* .manage-padding-and-width {
    width: 69vw;
  } */
  .investments_and_vault_stats {
    width: 295px;
  }
}

@media only screen and (max-width: 1150px) {
  /* .manage-padding-and-width {
    width: 66vw;
  } */
}
