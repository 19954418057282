.link-styling {
  cursor: pointer;
}

.link-styling:hover {
  text-decoration: none !important;
}

.link-styling:active {
  color: teal;
}

.breadcrumb-styling {
  display: flex;
  flex-direction: row;
  padding-left: 30px;
  align-items: center;
}
.breadcrumb-styling p {
  font-size: 14px;
  text-transform: capitalize;
  font-family: 'Open Sans';
}

@media only screen and (max-width: 1641px) {
  .breadcrumb-styling p {
    font-size: 12px;
  }
  .MuiBreadcrumbs-separator {
    margin-left: 6px !important;
    margin-right: 6px !important;
    margin-bottom: -3px;
  }
}

@media only screen and (max-width: 1380px) {
  .breadcrumb-styling {
    padding-left: 15px;
  }
}
