.custom-table-component {
  width: 100%;
  /* min-height:248px; */
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 4px 4px 30px rgba(27, 62, 105, 0.16);
  padding: 19px 20px 25px 20px;
}

#growth {
  color: #188038;
}

#decline {
  color: #c42121;
}

.custom-table-heading {
  color: #164F58;
  font-family: Catamaran;
  font-size: 24px;
  font-weight: bold;
  padding-bottom: 10px;
}

.custom-table-balance {
  font-size: 16px;
  color: #164F58;
  font-weight: bold;
  padding-bottom: 10px;
}

.custom-table-contents {
  display: flex;
  border-bottom: 1px solid #dee5ef;
  padding-top: 15px;
  justify-content: space-between;
}

.custom-table-content-text {
  font-size: 14px;
  color: #6d7681;
}

.custom-table-content-value {
  font-size: 14px;
  font-weight: bold;
  cursor: default;
  white-space: nowrap;
}

@media only screen and (max-width: 1641px) {
  .custom-table-content-value {
    font-size: 12px;
  }
  .custom-table-content-text {
    font-size: 12px;
  }
  .custom-table-balance {
    font-size: 13px;
  }
  .custom-table-heading {
    font-size: 20px;
  }
  .custom-table-component {
    width: 100%;
  }
  .custom-table-contents {
    padding-top: 12px;
  }
}
