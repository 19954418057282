.mainSafeContainer {
  display: flex;
  /* height: 100vh; */
  width: 100%;
}

.mainLeftContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.safeDetailsContainer{
  display:flex;
  flex-direction: column;
  /* box-shadow: .2px 2px 9px -2px #888; */
  box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
  border-radius: 5px;
  padding:20px;
  margin-bottom: 20px;
}
.safeHeadingContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between */
}
.vaultDetailsSmallContainer{
  /* background-color:blue; */
  margin-left:10%;
}
.managerAndDate {
  display: flex;
  flex-direction: row;
}
.colorVault{
  color :rgb(92, 90, 90);
}
.smallheadingVault{
  font-weight: bold;
  color :rgb(80, 78, 78);
}
.managerContainer {
  flex-direction: column;
  width: 100%;
 
  /* box-shadow: .2px 2px 9px -2px #888; */
  box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
  border-radius: 5px;
  /* width:35%; */
  padding:10px 10px 10px 0px;

}
.individualManager{
  display:flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */
  
}
.individualVault{
  display:flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */
}
.dateContainer {
  width: 30%;
  /* box-shadow: .2px 2px 9px -2px #888; */
  box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
  border-radius: 5px;
  margin:0px 20px;
  padding:10px 0px;
}
.valueRight{
    color:rgb(92, 90, 90);
    font-weight:normal;
    font-size:16px;
    display: flex;
    flex: 15
    /* margin:0px 10px; */
}
.valueRightDate{
  color :rgb(92, 90, 90);
  font-weight:normal;
  font-size:16px;
  padding:5px 5px 5px 20px;
}
.inlinP{
  padding:5px 5px 5px 20px;
  line-height: 30px;
  display:flex;
  flex:2;
  /* font-weight:bold; */
}
.inlinPBig{
  font-size:18px;
  padding:5px 5px 5px 20px;
}
.mainHeading{
  font-size:20px;
  padding: 20px 20px 20px 0px;
}
.inlinPBigOut{
  font-size:18px;
  padding:5px 5px 5px 0px;
  margin-top:20px;
}
.assetContainer{
  display:flex;
  flex-direction: row;
}
.imageContainer{
  display:flex;
  flex-direction: column;
  align-items: center;
  min-width: 120px;
  width:8%;
  margin-top:20px;
  margin-right:20px;
  padding:10px;
  /* box-shadow: .2px 2px 9px -2px #888; */
  box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
  border-radius: 5px;
}
.colorAssetSymbol{
  color :rgb(114, 114, 114);
  font-size:12px;
}

.strategyProtocolContainer{
  display:flex;
  width:100%;
  /* flex-wrap: wrap; */
  overflow-y:auto;
  /* background-color:lightblue; */
}
.StrategysubContainer{
  background-color:white;
  display:flex;
  flex-direction:column;
  padding:20px;
  margin:10px;
  /* box-shadow: .2px 2px 9px -2px #888; */
  box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
  border-radius: 5px;
  width:270px;
  height:140px;
  overflow-x:auto;
}
.StrategysubContainer::-webkit-scrollbar {
  display: none;
}

.strategyContainer{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
}
.strategyName{
  font-size:16px;
}
.valueLeft{
  color:rgb(92, 90, 90);
}

.safe-main-data-holder{
  padding-left: 30px;
  width: 65.91vw;
}

.safe-main-data-holder td{
  padding: 1px;
}


@media only screen and (max-width: 1720px) {
  .safe-main-data-holder{
    width:63.91vw;
   }
}

@media only screen and (max-width: 1618px) {
  .safe-main-data-holder{
    width:61.91vw;
   }
}

@media only screen and (max-width: 1516px) {
  .safe-main-data-holder{
    width:59.91vw;
   }
   .investments_and_vault_stats {
    width: 265px;
  }
}

@media only screen and (max-width: 1446px) {
  .safe-main-data-holder{
    width:57.91vw;
   }
   .investments_and_vault_stats {
    width: 265px;
  }
}

@media only screen and (max-width: 1380px) {
  .safe-main-data-holder{
    width:55vw;
    padding-left:15px;
   }
}

@media only screen and (max-width: 1279px) {
  .safe-main-data-holder{
    width:69vw;
   }
   .investments_and_vault_stats {
    width: 295px;
  }
}

@media only screen and (max-width: 1150px) {
  .safe-main-data-holder{
    width:66vw;
   }
}



/* 
@media only screen and (max-width: 600px) {
  .managerContainer{
   width:50%
  }
} */