.safeWrapper {
  /* height: 235px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.safeWrapper .iconWrap {
  display: flex;
  justify-content: space-evenly;
  width: 40%;
}
.safeWrapper .readOnlyText {
  border: none;
  color: white;
  font-family: "Averta", "Roboto", "Helvetica Neue", "Arial", "Segoe UI",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "-apple-system",
    "BlinkMacSystemFont", sans-serif;
  background-color: #b2b5b2;
  height: 1.5em;
  margin: 8px 0 0 0 !important;
  border-radius: 4px;
  pointer-events: none;
}
.safeWrapper .networkInfo {
  height: 24px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background-color: #164F58;
  text-transform: uppercase;
  font-family: "Catamaran", sans-serif;
  font-weight: 400;
}
.safeWrapper button {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  outline: none;
  color: #6d7681;
  text-transform: uppercase;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 10px;
  text-decoration-line: underline;
}

.safeWrapper .profilePhotoDropdownWrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.safeWrapper .dropDown {
  color: rgb(139, 139, 139);
}

.safeWrapper .makeStyles-root-6 {
  width: 100% !important;
}

@media only screen and (max-width: 1640px) {
  /* .safeWrapper{
        height:185px;
    } */
}
