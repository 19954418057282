.ant-tag {
  border-radius: 22px !important  ;
}

.base-tag,
.vault-tag,
.protocol-tag {
  color: #fff !important;
  padding: 1px !important;
  padding-right: 6px !important;
  margin-right: 5px !important;
}

.base-tag {
  background: #22c55e !important;
}

.vault-tag {
  background: #60b43994 !important;
  padding: 1px 6px !important;
}

.protocol-tag {
  background: #66ddff !important;
  padding: 1px 6px !important;
}

.base-tag .anticon-close,
.vault-tag .anticon-close {
  color: #ffffff82;
}

#tag-item .ant-avatar {
  color: #1b3e69;
  margin-right: 6px;
}

.ant-tag-hidden#tag-item {
  display: block !important;
}
