.outerbox {
    margin: 0 5px;
    padding: 2px;
    background: #DEE5EF 0% 0% no-repeat padding-box;
    border-radius: 4px;
    border: none;
    opacity: 1;
    width: 130px;
    height: 27px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    cursor: pointer;
}
.button-content {
    text-align: center;
    font: normal normal bold 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #164f58;
    text-transform: uppercase;
    opacity: 1;
}