.styled-sidebar-parent-style{
    min-height:638px;
    width:25%;
    background-color: #DEE5EF;
    border-radius: 8px 0px 0px 8px;
    /* max-width: 220px; */
    /* box-shadow: 4px 4px 30px rgba(27, 62, 105, 0.16); */
}

.styled-sidebar-button-styling{
    border:none;
    width:100%;
    height:48px;
    background-color: #DEE5EF;
    transition: 0.3s all ease;
}

.styled-sidebar-button-styling:hover{
    cursor:pointer;
}

.styled-sidebar-button-styling p{
    text-align: start;
    font-family: Catamaran;
    color:#164f58;
    padding-left: 15px;
}

.styled-sidebar-button-styling-selected{
    border:none;
    width:100%;
    height:48px;
    background-color: white;
    transition: 0.3s all ease;

}
.styled-sidebar-parent-style-margin{
    margin-top:30px
}

.styled-sidebar-button-styling-selected p{
    text-align: start;
    font-family: Catamaran;
    font-weight: bold;
    padding-left: 15px;
    color:#164f58;
}

@media only screen and (max-width: 1641px) {
    .styled-sidebar-route-name{
      font-size:13px;
    }
    .styled-sidebar-button-styling{
        height:42px;
    }
    .styled-sidebar-button-styling-selected{
        height:42px;
    }
    .styled-sidebar-parent-style-margin{
        margin-top:26px
    }
  }
