.user-data-cell {
  display: flex;
}

.user-data-cell .user-image {
  width: 20px;
  height: 20px;
  display: flex;
}

.user-data-cell .user-image img {
  width: 100%;
  height: auto;
}

.user-data-cell .user-bio {
  font-size: 14px;
  font-family: "Open Sans";
  color: #1b3e69 !important;
  font-weight: 700 !important;
  padding-left: 14px;
}

.table-data-format thead th,
.table-data-format tbody td {
  font-size: 14px;
  font-family: "Open Sans";
  font-weight: 700 !important;
  color: #6d7681;
}

.table-data-format thead th {
  background-color: #dee5ef !important;
  color: #1b3e69 !important;
}

.table-data-format tbody td {
  border-bottom: 1px solid #dee5ef !important;
  position: relative;
}

.table-data-format tbody td.positive {
  color: #17ba49;
  padding-left: 15px;
}

.table-data-format tbody td span {
  position: relative;
}

.table-data-format tbody td span:before {
  content: "";
  position: absolute;
  top: 3px;
  left: -15px;
  width: 14px;
  height: 13px;
  background-repeat: no-repeat;
  background-size: cover;
}

.table-data-format tbody td.positive span:before {
  background-image: url(../../../assets/icons/posvalue.svg);
}

.table-data-format tbody td.negative span:before {
  background-image: url(../../../assets/icons/negvalue.svg);
}

.table-data-format tbody td.negative {
  color: #c42121;
}

.table-data-format .table-data-pagination {
  display: flex;
  text-align: center;
  float: right;
  padding: 10px;
}

.table-data-format .table-data-pagination span {
  align-self: center;
}

.table-data-format .table-data-pagination .pagination-button {
  width: 30px;
  border: none;
  background-color: transparent;
}

.table-data-format .table-data-pagination ul {
  display: flex;
  flex-direction: row;
}

.table-data-format .table-data-pagination ul li:nth-child(1) {
  order: 2;
  margin: 0px 20px 0px 40px;
}

.table-data-format .table-data-pagination ul li:nth-child(2) {
  order: 3;
}

.table-data-format .table-data-pagination ul li:nth-child(3) {
  order: 4;
  width: 21px;
  min-width: 21px;
  height: auto;
}

.table-data-format .table-data-pagination ul li:nth-last-child(2) {
  order: 5;
}

.table-data-format .table-data-pagination ul li:last-child {
  order: 1;
}

.table-data-format
  .table-data-pagination
  ul
  li:not(:first-child, :nth-child(2), :nth-child(3), :nth-last-child(2), :nth-last-child(1)) {
  color: #1b3e69;
  display: none;
}

.table-data-format .no-vaults {
  display: flex;
  justify-content: center;
  text-align: center;
  justify-self: center;
  min-height: 500px;
}

.table-data-format .no-vaults .no-vaults-text {
  font-size: 30px;
  font-weight: 500;
  color: #1b3e69;
}

.table-data-format .table-data-pagination .ant-pagination-item,
.table-data-format .table-data-pagination .ant-pagination-item-active {
  border: none;
  min-width: 0px;
  width: 0px;
  height: 0px;
  background-color: transparent;
  color: #1b3e69;
}

.table-data-pagination
  .ant-pagination-item.ant-pagination-item-1
  .pagination-page-no {
  border: 1px solid black;
  border-radius: 4px;
  padding: 5px 10px;
  background-color: transparent;
  color: #1b3e69;
  display: contents;
}

.table-data-pagination .ant-pagination-item .pagination-page-no {
  display: none;
}
