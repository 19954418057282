.main-box-container{
    height:210px;
    min-width: 200px;
    background-color: white;
    box-shadow: 4px 4px 30px rgba(27, 62, 105, 0.16);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 20px;
    flex: 1 1 0;
    max-width: 50%;
}

.add-custom-strategy-button{
    position: absolute;
    bottom:15%;
    padding: 5px 10px;
    background-color: #1B3E69;
    border-radius: 8px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.add-custom-strategy-button p{
    color: white;
    font-family: Catamaran;
    font-weight: bold;
}

.add-custom-strategy-button:hover{
    cursor: pointer;
}

.add-custom-strategy-main-image{
    height: 6rem;
    position: absolute;
    top: 10%;
}
