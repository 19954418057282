iframe {
  display: none;
}

.queue-wrap {
  padding-top: 3%;
  padding-left: 15%;
  /* width:31vw; */
  text-align: left;
}

.queue-wrap .MuiTimelineItem-root::before,.queue-wrap .MuiTimelineItem-missingOppositeContent {
  width: 0;
  padding: 0;
}

.queue-wrap .separation {
  height: 150px;
}

.queue-wrap .separation .loader {
  color: #00bff5;
}

.queue-wrap .separation .loader-false {
  color: transparent;
}

.queue-wrap .separation .circle-number {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  border-radius: 50%;
}

.queue-wrap .separation .MuiTimelineDot-root {
  height: 38px;
  height: 38px;
}

.queue-wrap .separation .icon {
  background-color: #1b3e69;
}

.queue-wrap .separation .icon-new {
  background-color: #dee5ef;
}

.queue-wrap .separation .icon-selected {
  background-color: #00bff5;
}

.queue-wrap .separation .icon .number,
.queue-wrap .separation .icon-new .number,
.queue-wrap .separation .icon-selected .number {
  color: white;
  font-weight: bold;
}

.queue-wrap .separation .connector {
  background-color: #1b3e69;
}

.queue-wrap .separation .neg-connector {
  background-color: #dee5ef;
  margin-top: 10px;
  margin-bottom: 10px;
}

.queue-wrap .contents {
  display: flex;
  width: 250px;
}

.queue-wrap .contents .create-vault-stages {
  color: #1b3e69;
  font-size: 16px;
  font-weight: bold;
  padding-left: 5px;
  padding-top: 5px;
}

.queue-wrap .contents .progress {
  width: 100%;
  margin-top: 10px;
  margin-left: 5px;
}

.queue-wrap .contents .progress .MuiLinearProgress-barColorPrimary {
  background-color: #15e253;
}

.queue-wrap .contents .custom-button {
  border-radius: 4px;
  background-color: #dee5ef;
  border: 0px;
  padding: 0px;
  margin-left: 50px;
  transition: 0.3s all ease;
}

.queue-wrap .contents .custom-button:hover {
  cursor: pointer;
  background-color: #cdd0d4;
}

.queue-wrap .contents .custom-button p {
  color: #1b3e69;
  font-weight: bold;
  padding: 4px 16px;
}
