.walletConnectWrapper {
  width: fit-content;
  align-items: center;
  /* position: absolute;
  right: 5px; */
}
.walletConnectWrapper .walletName {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  color: #164F58;
  float: left;
  line-height: 1em;
}
.walletDetails {
  width: 147px;
  padding-right: 10px;
  padding-left: 10px;
}


.sidebar {
  grid-area: sidebar;
}

.content {
  grid-area: content;
}

.wallet-connected-header {
  grid-area: header;
}

.footer {
  grid-area: footer;
}

.wallet-wrapper {
  display: grid;
  padding: 10px;
  grid-template-columns: 20% 80% ;
  grid-template-areas:
    "header header"
    "sidebar content "
    "footer footer  ";
}

.footer {
  display: flex;
  justify-content: center;
}
.wallet-connected-header {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-left:10px;
}
.wallet-wrapper .addressText {
  font-size: 14px;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
  color: #1b3e69;
}

.footer button {
  background-color: #f02525;
  color: #ffffff;
  text-transform: uppercase;
  width: 90%;
  font-size: 16px;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
  border-radius:8px;
}
.footer button:hover {
  background-color: black;
}
