.transaction-info-card-main-container{
    margin: 0 auto;
    height: 40px;
    width: 450px;
    background-color: white;
    box-shadow:  4px 4px 30px rgba(27, 62, 105, 0.16);
    border-radius: 8px;
    overflow: hidden;
}

/* position:"fixed",width:"100vw",height:"40px",bottom:"30px" */
.transaction-aligner-component{
    position:fixed;
    width: 100vw;
    height: 40px;
    bottom: 30px;
    z-index: 1;
}



.transaction-info-text-styling{
    color:#1B3E69;
    font-size: 14px;
    font-weight: bold;
}

#myProgress {
    width: 100%;
    background-color: #b8c3d4;
  }
  
  #myBar {
    width: 1%;
    height: 30px;
    background-color: #1B3E69;
  }

@media only screen and (max-width: 1300px) {
.transaction-info-card-main-container{
    width: 350px;
}
.transaction-info-text-styling{
    font-size: 11px;
}
}
.transaction-info-card-main-container:hover{
    cursor:pointer;
}

.transaction-info-card-main-container::before{
    transition: 0.3s all fade-in;
}