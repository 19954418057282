 .safedetails-whitelist-manager-styling{
   margin-top:30px;
}



#safedetails-manager-button-styling{
    border:0px;
    padding:0px;
    background-color: transparent;
    
 } 

 #safedetails-manager-button-styling:hover{
     cursor: pointer;
 }

 #safedetails-manager-button-styling p{
    color:#1B3E69;
    font-size: 14px;
    font-weight: bold;
    text-decoration: underline;
 }

 
@media only screen and (max-width: 1641px) {
   #safedetails-manager-button-styling p{
     font-size:12px;
   }
  
 }