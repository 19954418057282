.investment-card {
  border: 2px solid #d1d1d1;
  border-radius: 8px;
  width: 24vw;
  /* min-height: 40vh; */
}

#investment_button {
  padding: 12px 20px 12px 20px;
  border: none;
  background: #0e2139;
  color: white;
  border-radius: 4px;
  transition: 0.3s;
}

.investment-button-and-table-gap {
  margin-top: 13px;
}

.token-balance-in-my-investment-card {
  display: flex;
  justify-content: center;
  align-items: center;
}

.MuiPaper-root .MuiTableContainer-root .MuiPaper-elevation1 .MuiPaper-rounded {
  box-shadow: none;
}

.not-allowed-to-deposit-and-withdraw-style {
  color: #c42121;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

@media only screen and (max-width: 1560px) {
  .investment-card {
    border: 2px solid #d1d1d1;
    border-radius: 8px;
    width: 39vw;
    /* min-height: 40vh; */
  }
  .deposit_withdraw_button_position {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}

@media only screen and (max-width: 1712px) {
  .investment-button-and-table-gap {
    margin-top: 11px;
  }
}

@media only screen and (max-width: 1507px) {
  .investment-button-and-table-gap {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 1390px) {
  .investment-button-and-table-gap {
    margin-top: 9px;
  }
}
