.pending-transaction-image-styler{
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pending-transaction-image-styler img{
    height:10rem;
}

.collapse-table-style-summary-transaction{
    width:100%;
    height:200px;
    background-color: #DEE5EF;
    padding: 15px 15px;
  
  }
