body {
  height: auto;
}
.helpCenterWrapper {
  background-color: #f8fafd;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.helpCenterWrapper .navbarHelpcenter {
  position: inherit;
}
.helpCenterWrapper .mainContent {
  width: 830px;
  margin-top: 80px;
  margin-left: auto;
  margin-right: auto;
  background: #f8fafd;
  height: auto;
}
.helpCenterWrapper .primaryContent,
.accordionContent,
.secondaryContent {
  margin-bottom: 36px;
}
.helpCenterWrapper .secondaryContent {
  height: auto;
}
.helpCenterWrapper .mainContent .initialHeading {
  font-size: 24px;
  font-weight: 700;
  color: #1b3e69;
  font-family: "Open Sans", sans-serif;
  margin-top: 10px;
  margin-bottom: 25px;
}
.helpCenterWrapper .mainContent .primaryHeading,
.accordionHeading,
.secondaryHeading {
  font-size: 18px;
  font-weight: 700;
  color: #1b3e69;
  font-family: "Open Sans", sans-serif;
  margin-top: 25px;
  margin-bottom: 10px;
}
.mainContent .paraContent {
  margin-top: 10px;
  text-align: justify;
}
.footerContactUsWrap {
  height: auto;
  width: 100%;
  padding: 5px 20px 5px 5px;

  display: grid;
  grid-template-rows: 55px;
  grid-row-gap: 10px;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;

  background-color: #fff;
  font-family: "Open Sans", sans-serif;
  box-shadow: 4px 4px 30px rgba(27, 62, 105, 0.16);
  border-top: 1px solid #dee5ef;
}
.footerContactUsWrap .contactUsHeading {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #1b3e69;
  text-transform: capitalize;
  font-weight: 700;
}
.footerContactUsWrap .mailId {
  font-size: 14px;
  color: #6b7075;
  font-weight: 400;
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  width: 100%;
  justify-content: center;
  grid-column-gap: 30px;
}
.footerContactUsWrap .anchorTagSocialMedia {
  text-decoration: none;
}
.helpCenterWrapper .accordionNoContent {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #6d7681;
}
.footerContactUsNoContent{
  height: auto;
  width: 100%;
  margin-top: auto;
  padding: 5px 20px 5px 5px;

  display: grid;
  grid-template-rows: 55px;
  grid-row-gap: 10px;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;

  position: absolute;
  bottom: 0px;

  background-color: #fff;
  font-family: "Open Sans", sans-serif;
  box-shadow: 4px 4px 30px rgba(27, 62, 105, 0.16);
  border-top: 1px solid #dee5ef;
}
.footerContactUsNoContent .mailId {
  font-size: 14px;
  color: #6b7075;
  font-weight: 400;
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  width: 100%;
  justify-content: center;
  grid-column-gap: 30px;
}
