.toaster-item {
  background: rgba(219, 219, 219, 0.44);
  border-radius: 4px;

  padding: 8px 15px 8px 15px;
  margin-top: 10px;
}

.toaster-message {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  color: #000;
  text-align: left;
}

.toaster-item .ant-col {
  display: flex;
  align-items: center;
}

.toaster-item .ant-row {
  padding-bottom: 0 !important;
}

.toaster-item .toaster-close-col {
  justify-content: flex-end;
}
