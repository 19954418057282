.notFoundContainer{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top:20vh;
  
}

.imageForPageNotFound{
    height:200px;
    opacity:76%;
}

.pageNotFoundText{
    color:#9b97b3;
}