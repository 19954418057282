.login-wrap {
  /* height: 80vh; */
  /* width: 100vw; */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8fafd;
}

.login-wrap .login-card {
  display: grid;
  height: 400px;
  width: 400px;
  background-color: #fff;
  /* box-shadow: 4px 4px 30px rgba(27, 62, 105, 0.1); */
  grid-template-columns: 1fr;
  grid-template-rows: 50px 200px 50px;
  align-items: center;
  justify-content: start;
  padding: 30px;
  /* border-radius: 8px; */
  grid-gap: 20px;
}

.login-wrap .login-heading {
  color: #1b3e69;
  /* font-family: "Open Sans", sans-serif; */
  font-family: "Catamaran", sans-serif;

  font-weight: 700;
  font-size: 28px;
}

.login-wrap .content-wrap {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-gap: 20px;
  align-self: start;
}
.login-wrap .useremail-heading,
.login-wrap .password-heading {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  height: 35px;
  width: 100%;
  padding: 5px;
  color: #1b3e69;
  /* border-bottom: 1px solid #c2c2c2; */
}

.login-wrap #username-id::placeholder,
.login-wrap #password-id::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #6d7681 !important;

  opacity: 1;
  /* Firefox */
}
.login-wrap #username-id,
.login-wrap #password-id {
  /* outline: none; */
  outline-color: #c2c2c2;
}
.login-wrap #username-id:focus,
.login-wrap #password-id:focus {
  outline-color: #1b3e69;
}

.login-wrap .login-button-wrap {
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-wrap .login-button {
  border: none;
  padding: 12px 100px;
  background: #b47c39;
  border-radius: 8px;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: 0.3s all ease;
}
