
.footerWrap .footerLinks{
    padding: 0 10px;
    color: #164F58;
}
/* .footerWrap .footerLinks:hover{
    color: #B47C39;
    text-decoration: underline;
} */
.footerWrap {
    display: flex;
    flex-direction: row;
    padding: 40px 100px 20px 10px;
    font: normal normal normal 14px/32px "Open Sans";
    color: #164F58;
    justify-content: right;
}