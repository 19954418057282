

  input[type="file"] {
    display: none;
}

/* #inputId::-ms-browse {
    background: black;
    color: red;
    padding: 1em;
  } */

.custom-file-upload {
    height:37.6px
    /* border: 1px solid #ccc; */
    /* display: inline-block; */
   

}
@media screen and (max-height: 635px) {
  .form-container-sdk {
    max-height: 310px;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none;
    
  }

  .form-container-sdk::-webkit-scrollbar {
    display: none;
  }
  
}

@media screen and (max-height: 600px) {
  .form-container-sdk {
    max-height: 270px;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none;
    
  }

  .form-container-sdk::-webkit-scrollbar {
    display: none;
  }
  
}

@media screen and (max-height: 550px) {
  .form-container-sdk {
    max-height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none;
    
  }

  .form-container-sdk::-webkit-scrollbar {
    display: none;
  }
  
}