.notFoundContainer{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top:20vh;
  
}

.imageForPageNotFound{
    height:350px;
    opacity:100%;
}

.pageNotFoundText{
    font-family: Catamaran;
    font-size: 30px;
    font-weight: bold;
}