.collapse-overview-table-style{
    width:100%;
    height:100px;
    background-color: #DEE5EF;
    padding: 15px 15px;
  
  }

  .address-hash-in-overview-table{
    width: 20vw;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 10px;
    font-size:14px;
  }

  @media only screen and (max-width: 1640px) {
    .address-hash-in-overview-table{
        font-size:12px;
      }
      .collapse-overview-table-style{
        height:85px;
      }
  }
  @media only screen and (max-width: 1380px) {
    .address-hash-in-overview-table{
        width: 16vw;
      }
    
  }