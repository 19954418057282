.container{
    display: block;
    text-align: center;
    justify-self: center;
    min-height: 500px;
}

.coming-soon-text {
  font-size: 80px;
  font-weight: 700;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: #1b3e69;
}
