*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: #1b3e69;
}
.edit-page .wrapper {
  width: 100%;
}

.edit-page .wrapper #loading {
  width: 100%;
  height: 50vh;
}

.edit-page .main-items {
  background: #f8fafd;
  width: 100%;
  margin: 0 auto;
  padding: 45px 10px;
  display: grid;
  grid-template-columns: 25% 74%;
  gap: 1%;
}

.edit-page .main-items .left-block-wrap {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: fixed;
  position: -webkit-sticky;
  /* top: 100px; */
  width: min-content;
}

.edit-page .main-items .left-block-wrap .toggle-view {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
}

.edit-page .main-items .left-block-wrap .toggle-view button {
  cursor: pointer;
  border: none;
  /* padding-right:10px; */
  background-color: transparent;
  text-align: left;
  font-weight: bold;
}

.edit-page .main-items .left-block-wrap .toggle-view .list {
  font-weight: bold;
  color: #00bff5;
}

.edit-page .main-items .left-block-wrap .toggle-view .grid {
  color: #1b3e69;
}

.edit-page .main-items .left-block-wrap .search {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 10px;
}

.edit-page .main-items .left-block-wrap .search h4 {
  text-align: left;
  word-break: normal;
  font-family: "Catamaran";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 13px;
  color: #b47c39;
}

.edit-page .main-items .left-block-wrap .search .search-input {
  display: flex;
  justify-content: flex-start;
  opacity: 1;
  box-sizing: border-box;
  background: #f8fafd;
  border: 1px solid #c4c4c4;
  border-radius: 2px;
  padding: 0;
  margin: 0;
  height: 38px;
}

.edit-page
  .main-items
  .left-block-wrap
  .search
  .search-input
  .ant-input-group
  .ant-input-group-addon {
  display: none;
}

.edit-page
  .main-items
  .left-block-wrap
  .search
  .search-input
  .ant-input-affix-wrapper {
  padding: 0px 11px;
  height: 38px;
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.edit-page
  .main-items
  .left-block-wrap
  .search
  .search-input
  .ant-input-affix-wrapper
  input {
  padding: 0px 11px;
  height: 38px;
  background-color: transparent;
  border: none;
}

.edit-page .main-items .left-block-wrap .search .autocomplete-data {
  overflow-y: auto;
  text-align: left;
  padding-left: 20px;
  list-style: none;
  box-shadow: 4px 4px 30px rgb(27 62 105 / 16%);
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  background-color: #fff !important;
  max-height: 250px !important;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.edit-page .main-items .left-block-wrap .search .autocomplete-data li span {
  cursor: copy;
}

.edit-page .main-items .right-block-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0% 10%;
}

.edit-page .main-items .card-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.edit-page .main-items .card-wrap .boxVault {
  background-color: transparent;
  margin: 5px 0px;
}

.edit-page .main-items .card-wrap .boxVault div {
  border-radius: 5px;
}

.edit-page .main-items .card-wrap .boxVault .active .contents #core,
.edit-page .main-items .card-wrap .boxVault .contents #core {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: auto;
}

.edit-page
  .main-items
  .card-wrap
  .boxVault
  .active
  .contents
  #core
  .settings-button,
.edit-page .main-items .card-wrap .boxVault .contents #core .settings-button {
  width: 100%;
}

.edit-page .main-items .card-wrap .boxVault #core .save-discard {
  margin-top: 10%;
  margin-bottom: -2%;
}

.edit-page .main-items .card-wrap .boxVault .save-discard {
  height: 49.55px;
  margin-top: 5%;
  display: grid;
  grid-template-columns: 2fr 2fr;
  gap: 20px;
}

.edit-page .boxVault .save-discard button {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  font-family: Catamaran;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 23px;
  text-transform: uppercase;
  padding: 0 40px;
  cursor: pointer;
}

.edit-page .boxVault .save-discard button:disabled {
  cursor: not-allowed;
  opacity: 0.8;
}

.edit-page .main-items .card-wrap .boxVault .discard {
  border: 1px solid #b47c39;
  color: #b47c39;
  background: transparent;
}

.edit-page .main-items .card-wrap .boxVault .active .discard {
  background: #fff;
}

.edit-page .main-items .card-wrap .boxVault .save {
  background: #b47c39;
  border: none;
  color: #f8fafd;
}


.edit-page .main-items .card-wrap .boxVault .not-updated {
  background: #b47d397a;
  border: none;
  color: #f8fafd;
}

.edit-page .main-items .right-block-wrap .button-wrap {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  margin-top: 10%;
  margin-right: -70%;
}

.edit-page .main-items .right-block-wrap .button-content {
  height: 61px;
  width: 278px;
  background: #b47c39 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  text-align: center;
  font: normal normal bold 18px/30px Catamaran;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 1;
  cursor: pointer;
  border: none;
}

.edit-page .main-items .contents #admin {
  width: 100%;
}

.support-divider .ant-divider {
  border-color: #1b3e69;
}

@media only screen and (max-width: 1379px) {
  .edit-page .main-items .contents #admin table td {
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@media only screen and (max-width: 1179px) {
  .edit-page .main-items .contents #admin table td {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
