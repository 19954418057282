.successMessageWrap,
.errorMessageWrap,
.warningMessageWrap {
  display: flex;
  align-items: center;
  height: 4vh;
  width: auto;
  background: "pink";
}
.successMessageWrap .successIcon {
  color: #188038;
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.successMessageWrap .successMessage,
.errorMessageWrap .errorMessage,
.warningMessageWrap .warningMessage {
  color: #1b3e69;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  font-size: 11px;
}
.errorMessageWrap .errorIcon {
  margin-right: 10px;
  display: flex;
  align-items: center;
  color: #c42121;
}

.warningMessageWrap .warningIcon {
  margin-right: 10px;
  display: flex;
  align-items: center;
  color: #b47c39;
}
.rc-notification-notice {
  border-radius: 8px !important;
  box-shadow: 4px 4px 30px rgba(27, 62, 105, 0.16) !important;
  margin-top: 20px !important;
  padding: 10px 30px 10px 20px !important;
  margin-left: -150px !important;
  min-height: 40px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  z-index: 1301 !important;
}
