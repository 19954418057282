.custom-strategy-display-table-styler {
  background-color: white;
  width: 100%;
  border-radius: 8px;
  padding: 20px 20px;
  min-height: 670px;
  box-shadow: 4px 4px 30px rgba(27, 62, 105, 0.16);
  
}

.custom-strategy-table-contents-placer{
  display: flex;
  flex-wrap: wrap;
  gap:20px;
  justify-content:flex-start;
}

.custom-strategy-page-aligner {
  padding: 1% 0px 0px 30px;
  width: 65.91vw;
}

.custom-strategy-table-first-flex {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
  }

p.titleText {
  color: #1b3e69;
  font-family: Catamaran;
  font-size: 36px;
}



@media only screen and (max-width: 1640px) {
  p.titleText {
    font-size: 30px;
  }
}

@media only screen and (max-width: 1720px) {
  .custom-strategy-page-aligner {
    width: 63.91vw;
  }
}

@media only screen and (max-width: 1618px) {
  .custom-strategy-page-aligner {
    width: 61.91vw;
  }
}

@media only screen and (max-width: 1516px) {
  .custom-strategy-page-aligner {
    width: 59.91vw;
  }
}

@media only screen and (max-width: 1446px) {
  .custom-strategy-page-aligner {
    width: 57.91vw;
  }
}

@media only screen and (max-width: 1380px) {
  .custom-strategy-page-aligner {
    width: 55vw;
    padding-left: 15px;
  }
}

@media only screen and (max-width: 1279px) {
  .custom-strategy-page-aligner {
    width: 69vw;
  }
}

@media only screen and (max-width: 1150px) {
  .custom-strategy-page-aligner {
    width: 66vw;
  }
}
