.vault-stats-table {
  border: 2px solid #d1d1d1;
  border-radius: 8px;
  width: 24vw;
}

.price-card-alignment-styler-in-dashboard {
  display: flex;
  justify-content: center;
  align-items: center;
}
