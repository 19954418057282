
div .loader-animation-ring-animae{
    /* height: 300px;
    width:400px;
    align-items: center;
    justify-content: center; */
    position: fixed;
top: 30%;
left: 15%;
width: 70%;
height: 25%;
z-index: 9999;
  }