.custom-strategy-listing-container {
  height: 210px;
  min-width: 200px;
  background-color: white;
  box-shadow: 4px 4px 30px rgba(27, 62, 105, 0.16);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 20px;
  flex: 1 1 0;
  max-width: 50%;
}
.custom-strategy-listing-container:hover{
    cursor: pointer;
}
.custom-strategy-listing-container-inner-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.custom-strategy-listing-table-image {
  height: 5rem!important;
  width: 5rem!important;
}

.avatar-title {
  font-family: Catamaran;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}
.avatar-description {
  font-size: 12px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
