.cards-in-safe-details-whitelist{
    display:flex;
    justify-content: center;
    align-items: center;
    gap:40px;
}
.button_styling{
    width:10vw;
    height:60px;
    background-image: linear-gradient(to left,#68AAFE, #3236E7);
    border-style: none !important;
    border-radius:28px;
    transition: transform .2s;
    outline: none;
}

.button_styling p{
    color:white;
    font-size:1rem;
}

.button_styling:hover{
    transform: scale(1.1);
    cursor:pointer;
}

.image-resizer-in-safedetails-settings{
    height:30px;
    /* margin-top:5px; */
}

.flex-in-button-safedetails{
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap:10px;
    position:static;
}

/* @media only screen and (max-width: 1111px) {
    .button_styling{
        width:15vw;
        
    }
  } */