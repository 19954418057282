p{
  font-family: 'Open Sans', sans-serif;
  font-size:16px;
  color:#6D7681
}

h1{
  font-family: 'Catamaran', sans-serif;
}

.Modal-module_modal__FqeFw  {
  --st-dialog-maxWidth: 480px;
  --st-dialog-maxContentHeight: 81vh;
  box-sizing: border-box;
}

.container{
  padding:0px 15px;
  max-width:1170px;
  margin:0 auto;
}



@media only screen and (max-width: 1281px) {
  .Modal-module_modal__FqeFw  {
    
      --st-dialog-maxContentHeight: 80vh;
  }
}