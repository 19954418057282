.queue-heading-styler {
  color: #1b3e69;
  font-family: "Catamaran";
  font-size: 24px;
  font-weight: bold;
}

.whitelist-descriptor{
  font-size: 14px;
  margin: 10px 0px 20px 0px; 
}

.pending-transaction-dropdown-table-content{
  font-size:14px;
  font-weight: bold;
}
.pending-transaction-dropdown-table-content-only{
  font-size:14px;
}

.pending-screen-table-heading{
  font-size: 14px;
  font-weight: bold;
}

.pending-transaction-table-content-size{
  font-size:14px;
}

.pending-transaction-image-styler {
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pending-transaction-image-styler img {
  height: 10rem;
}

.collapse-table-style-pending-transaction {
  width: 100%;
  /* min-height: 128px; */
  height: 100%;
  background-color: #dee5ef;
  padding: 10px 15px;
}

.error-red-div-box {
  height: 10px;
  width: 10px;
  background-color: #e02b25;
  border-radius: 50%;
  margin-top: 5px;
  animation: redglow 1s infinite alternate;
}

.glowing-div-box {
  height: 10px;
  width: 10px;
  background-color: #E1AD01;
  border-radius: 50%;
  margin-top: 5px;
  animation: glow 1s infinite alternate;
}

@keyframes glow {
  from {
    box-shadow: 0 0 10px -3px #fddd75;
  }
  to {
    box-shadow: 0 0 10px 3px #fddd75;
  }
}

@keyframes redglow {
  from {
    box-shadow: 0 0 10px -3px #ee6c6c;
  }
  to {
    box-shadow: 0 0 10px 3px #f3b0b0;
  }
}

@media only screen and (max-width: 1640px) {
  .queue-heading-styler {
    font-size: 20px;
  }
  .whitelist-descriptor{
    font-size: 12px;
    margin: 10px 0px 10px 0px; 
  }
  .pending-screen-table-heading{
    font-size: 13px;
  }

  .pending-transaction-table-content-size{
    font-size:12px;
  }

  .pending-transaction-dropdown-table-content{
    font-size:12px;
  }
  .pending-transaction-dropdown-table-content-only{
    font-size:12px;
  }
  
}
