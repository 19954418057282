.table-aligner-in-safedetails{
    /* width:80%;
    display:flex;
    align-items:center;
    justify-content:center;
    border:2.2px solid#D1D1D1;
    border-radius: 8px;
    width:95%; */
}

.display-whitelist-addresses-header-component{
    /* position: sticky;
    top: 0px;
    z-index: 1;
    background:white; */
}

.all-whitelist-elements-table-heading{
    margin-top: 20px;
    font-weight: bold;
    color:#1B3E69;
}

@media only screen and (max-width: 1641px) {
    .all-whitelist-elements-table-heading{
      font-size:13px;
    }
   
  }
 /* .Modal-module_modal__FqeFw{
      
    --st-dialog-maxWidth: 550px;
    --st-dialog-maxContentHeight: 80vh;
    box-sizing: border-box;
  
} */

 /* .Modal-module_modal__FqeFw{
    --st-dialog-maxWidth: 550px;
    --st-dialog-maxContentHeight: 80vh;
    box-sizing: border-box;
} */