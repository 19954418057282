.title {
  font-weight: 400;
  font-size: 20px;
  font-style: normal;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.titleExploreWrap {
  position: absolute;
  left: 250px;
  padding: 2px;
}
.totalBalance {
  position: absolute;
  right: 320px;
  font-size: 14px;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  margin-right: 30px;
  color: #164F58;;
}
.totalBalance .totalBalanceValue {
  font-size: 14px;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
  color: #164F58;;
}
.totalBalance .totalBalanceTExt {
  margin-right: 20px;
  color: #164F58;;
}
.akkrueLogo {
  width: 230px;
  height: 60px;
  background-color: #164F58;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1200;
  position: fixed;
}
.akkrueLogoRest {
  width: 230px;
  height: 60px;
  background-color: #164F58;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1200;
  position: fixed;
}

.titleExploreWrapVault {
  position: absolute;
  left: 250px;
  padding: 2px;
}

/* logo component */
.akkrueLogo #logo,
.akkrueLogoRest #logo{
  /* padding: 25px 25px 25px 0; */
  height: 28px;
}

@media only screen and (max-width: 1640px) {
  .titleExploreWrap {
    font-size: 12px;
    left: 210px;
  }
  .titleExploreWrapVault {
    font-size: 12px;
    left: 210px;
  }
  .totalBalance {
    right: 260px;
    font-size: 12px;
  }
  .akkrueLogo,
  .akkrueLogoRest {
    width: 200px;
  }

  .totalBalance .totalBalanceValue {
    font-size: 12px;
    color:#164F58;
  }
  .totalBalance .totalBalanceTExt {
    margin-right: 9px;
    color:#164F58;
  }
}
@media only screen and (max-width: 1279px) {
  .titleExploreWrapVault {
    left: 50px;
  }
  /* .akkrueLogo {
    display: none;
  } */
}
@media only screen and (max-width: 1080px) {
  /* .totalBalance {
    right: 270px;
  } */
}

.total-and-wallet {
  width: auto;
  position: absolute;
  right: 5px;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
}

.total-and-wallet .total {
  width: fit-content;
  align-items: center;
}

.total-and-wallet .total .total-balance {
  font-size: 14px;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  padding-right: 30px;
  color: #164F58;
}

.total-and-wallet .total .total-balance .total-balance-value {
  font-size: 14px;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
  color: #164F58;
}

.total-and-wallet .total .total-balance .currency {
  font-size: 10px;
  font-weight: 400;
  color: #164F58;
}
.total-and-wallet .total .total-balance .currency {
  font-size: 10px;
  font-weight: 400;
  color: #164F58;
}

.wallet {
  width: fit-content;
  align-items: center;
  color: #164F58;
}
/* .wallet {
  width: fit-content;
  align-items: center;
} */
