.new-deposit-withdraw-button-style {
  width: 100%;
  height: 43px;
  border-radius: 8px;
  border: 0px;
  cursor: pointer;
}

.new-deposit-withdraw-button-style p {
  color: white;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0;
}

#new-deposit {
  background-color: #22c55e;
  transition: 0.3s all ease;
}

#new-deposit:hover {
  background-color: #36D06F;
}

#new-withdraw {
  background-color: #164f58;
  transition: 0.3s all ease;
}

#new-withdraw:hover {
  background-color: #1B6E7D;
}

.deposit-withdraw-button-component {
  padding-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  transition: 0.3s all ease;
}

@media only screen and (max-width: 1641px) {
  .new-deposit-withdraw-button-style {
    width: 100%;
  }
  .new-deposit-withdraw-button-style p {
    font-size: 13px;
  }
  .deposit-withdraw-button-component {
    padding-top: 0px;
  }
}
