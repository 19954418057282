.boxVault {
  display: block;
  background-color: #ffffff;
}
.boxVault > div {
  border-radius: 8px;
  width: 100%;
  padding: 16px 20px;
  box-shadow: 4px 4px 30px #1b3e691a;
  margin-bottom: 20px;
  min-height: 200px;
}
.boxVault:last-child > div {
  margin-bottom: 0;
}
/* .boxVault .darkblue {
  border: 2px solid #1b3e69;
}
.boxVault .lightblue {
  border: 2px solid #09c1f5;
}
.boxVault .brown {
  border: 2px solid #b88346;
}
.boxVault .green {
  border: 2px solid #19ba4b;
} */

.boxVault .darkgreen {
  border: 2px solid #164f58;
}

.boxVault .lightgreen {
  border: 2px solid #22c55e;
}

.green .contents {
  padding-bottom: 0 !important;
}

.boxVault .active {
  background: #6d7681 0% 0% no-repeat padding-box;
  box-shadow: 4px 4px 30px #1b3e691a;
  border: 2px solid #6d7681;
  opacity: 1;
  color: #ffffff;
}

.boxVault .active .title-button {
  background-color: #17ba49;
}

.boxVault .active .subtext,
.boxVault .active .sub-title {
  color: #fff;
}

.boxVault .contents .content-items {
  position: relative;
}

.boxVault .active .contents .content-items .add {
  border: 1px solid #17ba49;
  border-radius: 4px;
  opacity: 1;
  min-height: 42px;
  color: #fff;
  width: 100%;
  align-content: center;
  justify-content: space-evenly;
}

/* .boxVault .active .contents .content-items#advisor .add {
  height: 56px;
} */

.boxVault .contents .content-items .add .ant-select-selector,
.boxVault .active .contents .content-items .add .ant-select-selector {
  background-color: transparent;
  height: 42px;
}

.boxVault .active .contents .content-items .add input {
  background-color: transparent;
  color: #ffffff;
  border: none;
  width: inherit;
  height: 42px;
  padding: 0 10%;
  font-size: 16px;
}

.boxVault .contents .content-items .add input {
  height: 42px;
  width: inherit;
  border-radius: 2px;
  border: 1px solid#6d7681;
}

.boxVault .active .contents .content-items .line {
  position: relative;
  opacity: 1;
  background-color: transparent;
  height: 0.5px;
}

.boxVault .active .contents .content-items .text {
  color: #fff;
  text-overflow: ellipsis;
}

.boxVault .heading {
  text-align: left;
  font: normal normal bold 24px/39px "Catamaran";
  letter-spacing: 0px;
  display: flex;
  justify-content: space-between;
}
.boxVault .heading .title-button {
  font: normal normal bold 20px "Catamaran";
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: none;
}
/* .boxVault .darkblue .heading {
  color: #1b3e69;
}
.boxVault .lightblue .heading {
  color: #09c1f5;
}
.boxVault .brown .heading {
  color: #b88346;
}
.boxVault .green .heading {
  color: #19ba4b;
} */

.boxVault .darkgreen .heading {
  color: #164f58;
}

.boxVault .lightgreen .heading {
  color: #22c55e;
}

.boxVault .subtext {
  text-align: left;
  font: normal normal normal 14px/19px "Open Sans";
  letter-spacing: 0px;
  color: #164f58;
  opacity: 1;
  padding-top: 3px;
}

.boxVault .contents {
  padding: 40px 0px 25px 0px;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto;
  gap: 22.5px;
}

.boxVault .contents .content-items#advisor {
  display: grid;
  grid-template-rows: 21px 0 21px;
}

.boxVault .contents .content-items#wallet {
  display: grid;
  grid-template-rows: auto 0.1px auto;
  grid-auto-flow: column;
}

.boxVault .contents .content-items#wallet .line,
.boxVault .contents .content-items#advisor .line {
  display: none;
}

.boxVault .contents .content-items .add {
  opacity: 1;
  min-height: 42px;
  max-height: fit-content;
  /* display: grid;
  grid-auto-columns: auto; */
  justify-content: space-evenly;
  align-content: center;
  gap: 10px;
  width: 100%;
}

.boxVault .contents .content-items .line {
  background-color: #70707080;
  height: 3px;
  opacity: 1;
}

.boxVault .contents .text {
  text-align: left;
  font: normal normal normal 14px/19px "Catamaran";
  letter-spacing: 0px;
  color: #164f58;
  opacity: 1;
  padding-top: 11px;
  padding-left: 2px;
}

.boxVault .active .contents .text,
.boxVault .active .contents .ant-col.text span {
  color: #fff;
}

.boxVault .contents .ant-col.text span {
  font-family: "Open Sans", sans-serif;
  margin-left: 1.5em;
}

.boxVault .heading .title-buttonclick {
  font: normal normal bold 20px "Catamaran";
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: none;
  color: #6d7681;
  background-color: #17ba49;
}

.grid-data-format .grid-row {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr;
  grid-auto-flow: row;
  column-gap: 3%;
}

.grid-data-format .cardview {
  padding: 20px;
  background-color: #fff;
  box-shadow: 4px -1px 25px -1px #1b3e691a;
  border-radius: 8px;
  margin-bottom: 30px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cardview-head {
  display: flex;
  justify-content: space-between;
}

.cardview-head h6 {
  font-size: 20px;
  font-family: "Catamaran";
  color: #164f58 !important;
  font-weight: 700 !important;
  margin: 0;
}

.card-head-right {
  display: flex;
}

.cardview-head .user-image {
  width: 24px;
  height: 24px;
  display: flex;
  align-self: center;
}

.cardview-head .user-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cardview-text {
  padding: 22px 0 25px;
}

.cardview-text p {
  font-size: 14px;
  font-family: "Open Sans";
  color: #164f58 !important;
  font-weight: 400 !important;
  margin: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.cardview-footer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.card-footer label {
  font-size: 15px;
  font-family: "Open Sans";
  color: #164f58 !important;
  font-weight: 400 !important;
  margin: 0 0 5px;
  line-height: 1;
  display: block;
}

.mulitCoin {
  display: flex;
  align-items: center;
  padding: 2px;
}

.mulitCoin img {
  width: 15px;
  height: 15px;
  margin-right: 3px;
}

.mulitCoin span {
  font-size: 18px;
  margin-left: 5px;
  color: #164f58;
  font-weight: bold;
  font-family: Catamaran;
  line-height: 1;
}

.card-footer .value-footer {
  line-height: normal;
}
.card-footer .value-footer span {
  font-size: 14px;
  font-family: "Open Sans";
  font-weight: 600 !important;
  position: relative;
  line-height: 26px;
}

.card-footer .value-footer.positive span {
  color: #17ba49;
}

.card-footer .value-footer.negative span {
  color: #c42121;
}

.card-footer .value-footer span:before {
  content: "";
  position: absolute;
  top: 3px;
  left: -15px;
  width: 14px;
  height: 13px;
  background-repeat: no-repeat;
  background-size: cover;
}

.card-footer .value-footer .positive:before {
  background-image: url(../../../assets/icons/posvalue.svg);
}

.card-footer .value-footer .negative:before {
  background-image: url(../../../assets/icons/negvalue.svg);
}

.card-footer .value-footer .positive {
  color: #19ba4b;
}

.card-footer .value-footer .negative {
  color: #c42121;
}
/* .boxVault .active .contents #admin,
.boxVault .contents #admin {
  width: inherit;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 50% 50%;
  gap: 20px;
} */

input {
  padding-left: 10px;
}

.boxVault .active .contents #core,
.boxVault .contents #core {
  display: grid;
  grid-template-columns: 2fr 2fr;
  text-align: center;
  gap: 10px;
}

.boxVault .active .contents #core .select,
.boxVault .contents #core .select {
  display: grid;
  grid-template-columns: 100%;
}

.boxVault .active .contents #core .select div {
  border: none;
}

.ant-progress-circle .ant-progress-text {
  font-family: Catamaran;
  font-weight: bold;
}

#core .ant-select-selector {
  min-height: 42px;
}

#core .ant-select-arrow {
  top: 21px;
}

#core .settings-button {
  height: 42px;
  margin: 0;
  font-family: "Catamaran";
  text-transform: uppercase;
  font-size: 12px;
  background: #dee5ef;
  line-height: 34px;
  color: darkslategrey;
}

.grid-data-format .no-vaults {
  display: flex;
  justify-content: center;
  text-align: center;
  justify-self: center;
  min-height: 500px;
}

.grid-data-format .no-vaults .no-vaults-text {
  font-size: 30px;
  font-weight: 400;
  color: #164f58;
}

.grid-data-format .grid-row .ant-spin-dot-spin {
  margin-top: 0;
}

.boxVault .contents .content-items .add button {
  margin: 2px;
  width: 35px;
  height: 34px;
  border-radius: 45%;
  /* border: none; */
  /* background: #dee5ef 0% 0% no-repeat padding-box; */
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font: normal normal normal 22px/33px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
}

.boxVault .active .contents .content-items .add button {
  margin: 15% 5%;
  color: black;
  background-color: transparent;
  border: 1px solid black;
  display: grid;
  justify-content: center;
  align-content: center;
  text-align: center;
}

.boxVault .contents .content-items .add span,
.boxVault .active .contents .content-items .add span {
  height: fit-content;
  /* margin: 0;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
}
.boxVault .contents .content-items .ant-select-selection-item {
  font-size: 14px;
  font-family: "Catamaran";
  text-align: left;
  line-height: 42px !important;
}

/* .ant-row {
  padding-bottom: 40px !important; 
} */

#admin .ant-upload {
  height: 42px;
  width: inherit;
}

#admin .ant-upload.ant-upload-drag {
  border: 1px dashed #6d7681;
  border-radius: 2px;
  background-color: transparent;
  height: 42px;
  width: inherit;
  display: block;
}

/* #admin .ant-upload.ant-upload-drag .ant-upload {
  padding: 0;
} */

#admin .ant-upload.ant-upload-drag .ant-upload-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

#admin .ant-upload.ant-upload-drag p.ant-upload-hint {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #6d7681;
  display: flex;
  justify-content: center;
  align-items: center;
}

.active #admin .ant-upload.ant-upload-drag p.ant-upload-hint {
  color: #fff;
}

#admin .ant-upload.ant-upload-drag p.ant-upload-hint span svg {
  height: 16px;
  width: 16px;
}

.boxVault .contents .content-items .add input::placeholder {
  color: #6d7681;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.boxVault .active .contents .content-items .add input::placeholder {
  color: #fff;
}

#admin .ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  position: relative;
  height: 66px;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  color: #fff;
}

#admin .ant-upload-list-item-error .ant-upload-list-item-name {
  color: #000;
}

.active #admin .ant-upload-list-item-error .ant-upload-list-item-name {
  color: #fff;
}

#admin .ant-upload-list-item-card-actions-btn {
  border-color: #6d7681;
}

.active #admin .ant-upload-list-item-card-actions-btn {
  border-color: #fff;
}

#admin .ant-upload-list-item-card-actions-btn .anticon svg {
  color: #6d7681;
}

.active #admin .ant-upload-list-item-card-actions-btn .anticon svg {
  color: #fff;
}

/* Asset cards */

.contents #assets {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.lightblue .contents {
  padding-bottom: 0px;
}

.contents #assets .content-items .add,
.contents #support .content-items .add {
  background: #f8fafd;
  border: 1px solid #6d7681;
  border-radius: 2px;
  min-height: 42px;

  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 3px 10px 3px 10px;
}

.lightblue .contents {
  padding-bottom: 0px;
}

/* Support Cards */

.contents #support .content-items .input-add input {
  background: #ffffff;
  border: 1px solid #6d7681;
  border-radius: 2px;
  width: 100%;
  min-height: 42px;
  color: #000;
  padding-right: 10px;
}

/* .contents #support .content-items .input-add {
  border: 1px solid #6d7681;
} */

.contents #support .content-items .input-add input::placeholder,
#support .tag-placeholder,
#assets .tag-placeholder {
  font-family: "Open Sans";
  font-size: 10px;
  color: #6d7681;
}

#support .tag-placeholder,
#assets .tag-placeholder {
  margin-bottom: 0;
}

#support .ant-row {
  padding-bottom: 0 !important;
}

.vaultwarpBox .ant-divider {
  border-color: #164f58;
  /* margin-top: 53px  ; */
}

.sub-title {
  font-size: 15px;
  font-weight: 600;
  font-family: "Catamaran";
  font-style: normal;
  color: #164f58;
  text-align: left;
}

.tag-item .anticon-close {
  color: #fff !important;
}

/* VAlidations */

.contents #support .content-items,
.contents #assets .content-items,
.contents #core .content-items {
  position: relative;
}

.content-items .validation-required {
  font-family: "Open Sans";
  color: #f12e2e;
  font-weight: 400;
  font-size: 10px;
  text-align: right;
  display: block;
  position: absolute;
  right: 3px;
}

.active .content-items .validation-required {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 10px;
  color: #22c55e;
  text-align: right;
}

.boxVault .contents .content-items .text .text-optional {
  color: #6d76818c;
}

.boxVault .active .contents .content-items .text .text-optional {
  color: #eef1f58c;
}

#admin-edit .grid {
  display: grid;
  padding: 10px 0;
  grid-template-columns: 1.5fr 2.5fr 2fr 2fr;
  grid-auto-flow: row;
  gap: 10px;
}

#admin .input-text-box {
  display: grid;
  grid-template-columns: 4fr 4fr;
  grid-auto-flow: row;
}

#admin-edit .text {
  text-align: left;
  font: normal normal normal 14px/19px "Catamaran";
  letter-spacing: 0px;
  color: #164f58;
  opacity: 1;
  padding: 10px 0px;
  align-items: center;
}

#admin-edit .value {
  padding: 10px 0;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: #22c55e;
  align-items: center;
}

.active #admin-edit .text {
  color: #ffffff;
}

.active #admin-edit .value {
  color: #22c55e;
}

#admin-edit .add-more {
  margin: 5% 0%;
  height: 42px;
  width: 100%;
  align-items: center;
  background: transparent;
  border: 1px dashed #b47c39;
  border-radius: 2px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #22c55e;
}

.active #admin-edit .add-more {
  color: #22c55e;
}

#admin-edit .input-box input {
  width: 90%;
  height: 42px;
  background: transparent;
  border: 1px solid #6d7681;
  border-radius: 2px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #6d7681;
}

.active #admin-edit .input-box input {
  border: 1px solid #000;
  color: white;
}

#admin-edit .toggle {
  display: grid;
  grid-auto-flow: column;
  text-align: left;
}

.active #admin-edit .ant-switch-checked {
  background: #22c55e;
}

#admin-edit .ant-switch-checked {
  background: #22c55e;
}

#admin-edit .input-text-area {
  display: grid;
}
#admin-edit textarea {
  border: 1px solid #6d7681;
  min-height: 85px;
  padding: 15px;
  background-color: transparent;
  color: #6d7681;
  width: 100%;
}
.active #admin-edit .input-text-area textarea {
  background-color: transparent;
  color: #ffffff;
  border: 1px solid black;
}
#admin-edit .input-text-area .text .value {
  font: normal normal normal 14px/19px "Catamaran";
  font-weight: 500;
}

#admin-edit .input-text-area {
  display: grid;
}

#admin-edit textarea {
  border: 1px solid #6d7681;
  min-height: 85px;
  padding: 15px;
  background-color: transparent;
  color: #6d7681;
  width: 100%;
}
.active #admin-edit .input-text-area textarea {
  background-color: transparent;
  color: #ffffff;
  border: 1px solid black;
}
#admin-edit .input-text-area .text .value {
  font: normal normal normal 14px/19px "Catamaran";
  font-weight: 500;
}

.content-items .add #description {
  width: 100%;
  height: 85px;
  padding: 2% 5%;
  background: #ffffff;
  border: 1px solid #6d7681;
  border-radius: 2px;
  color: #6d7681;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

.active .content-items .add #description {
  background: transparent;
  color: #ffffff;
}

#description .brown-text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #22c55e;
}
.active #description .brown-text {
  color: #22c55e;
}
.cardview .cardview-description-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 33.14px;
  font-family: "Open Sans";
  font-style: normal;
  line-height: 16px;
  color: #164f58;
  background: rgba(222, 229, 239, 0.34);
  border-radius: 6px;
  align-items: center;
  padding: 0 10px;
  margin-bottom: 28px;
  margin-top: 15px;
}

.cardview .cardview-description-content .description-icon,
.cardview .cardview-description-content .description-icon img {
  width: 20px;
}

.cardview .cardview-description-content .description-text {
  display: flex;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cardview .cardview-description-content .read-more {
  font-weight: 400;
  font-size: 12px;
  color: #22c55e;
}

.complete-card {
  width: 18vw;
  height: fit-content;
  background: #f3f6f9;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  vertical-align: middle;
  padding: 5px;
}

.complete-card .title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #164f58;
  margin-bottom: 5px;
}

.complete-card .description-text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #164f58;
}

#admin-edit .vault-type-group {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

#admin-edit .vault-type-group .vault-type-radio {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #164f58;
}
.active #admin-edit .vault-type-group .vault-type-radio {
  color: #ffffff;
}
#admin-edit
  .vault-type-group
  .vault-type-radio
  .ant-radio-checked
  .ant-radio-inner {
  border-color: #22c55e;
}
#admin-edit .vault-type-group .vault-type-radio .ant-radio-inner::after {
  background-color: #22c55e;
}
.active
  #admin-edit
  .vault-type-group
  .vault-type-radio
  .ant-radio-inner::after {
  background-color: #22c55e;
}

.complete-card .read-more-link {
  text-align: right;
  cursor: pointer;
  font-weight: 400;
  font-size: 12px;
  color: #22c55e;
}
.complete-card .text-and-link {
  display: grid;
  grid-template-columns: 5fr 0.2fr;
}
.complete-card .read-more-link:hover {
  color: #164f58;
  font-weight: 500;
}

.contents .content-items textarea {
  background: #ffffff;
  border: 1px solid #6d7681;
  border-radius: 2px;
  padding: 5px 10px;
}

.active .contents .content-items textarea {
  background-color: transparent;
  padding: 5px 10px;
}
.modal-description .ant-modal-header {
  background-color: #164f58;
}
.modal-description .ant-modal-title {
  color: #fff;
}
