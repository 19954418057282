.naked-text-box-deposit-modal{
    background:#394049;
    width:86%;
    display:flex;
    /* justify-content: center; */
    /* margin-left: 30px;
    margin-top: 20px; */
   
}

.MuiInputBase-input{
    height:60px;
}