.dropdown-informative-table {
  display: flex;
  height: 15rem;
}

.history-table-heading {
  color: #164f58;
  font-family: "Catamaran";
  font-size: 24px;
  font-weight: bold;
}

.history-table-description {
  font-size: 14px;
  margin: 10px 0px 20px 0px;
}
.informative-table-row-1 {
  display: flex;
  flex-direction: column;
  width: 48%;
}

.informative-table-row-2 {
  display: flex;
  width: 48%;
  border-left: 2px #e8e7e6 solid;
  justify-content: left;
}

.informative-table-row-1-subpart-1 {
  height: 60%;
  border-bottom: 2px #e8e7e6 solid;
  padding-top: 1rem;
  margin-left: 2rem;
}

.informative-table-row-1-subpart-2 {
  height: 40%;
  display: flex;
  align-items: center;
  margin-left: 2rem;
}

.informative-table-row-1-subpart-2-avatar-image-styler {
  height: 40px;
  border-radius: 50%;
}

.transaction-page-button-copy-redirect-styling {
  background: none;
  border: 0px;
  cursor: pointer;
  padding: 0;
}

.informative-table-row-2 .MuiTimelineItem-missingOppositeContent::before {
  flex: 0;
}

.no-data-to-display-in-table {
  min-height: 30vh;
  width: 80vw;
  margin-top: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.address-hash-in-transaction-table {
  width: 20vw;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
  font-size: 14px;
}

.transaction-address-in-transaction-table-second-div {
  /* display: flex; */
  /* align-items: center; */
  text-align: center;
  width: 20vw;
  overflow: hidden;
  text-overflow: ellipsis;
}

.transaction-history-details-main-table {
  background-color: white;
  height: 100%;
  width: 100%;
  border-radius: 0px 8px 8px 0px;
  padding: 30px 50px;
}

.MuiTableContainer-root {
  box-shadow: none !important;
}

.MuiTableCell-root {
  border-bottom: none !important;
  /* padding:16px 0px !important; */
}

.MuiTableCell-root .MuiTableCell-body {
  padding: 0px !important;
}

.collapse-table-style {
  width: 100%;
  height: auto;
  background-color: #dee5ef;
  padding: 15px 15px;
}
/* .MuiTimelineItem-root .MuiTimelineItem-alignLeft .MuiTimelineItem-missingOppositeContent{
    flex:0;
} */
.history-table-heading-export{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.history-table-custom-scroll {
  width: 800px;
  overflow-x: auto !important;
  scrollbar-width: thin;
}

.history-table-custom-scroll::-webkit-scrollbar {
  width: 10px;
}

.history-table-custom-scroll::-webkit-scrollbar-track {
  background: #dee5ef;
}

.history-table-custom-scroll::-webkit-scrollbar-thumb {
  background: #0e2139;
  border-radius: 8px;
  height: 20px;
}
.history-table-row-heading {
  font-size: 14px;
  font-weight: bold;
}
.history-table-inner-contents {
  font-size: 14px;
}

@media only screen and (max-width: 1640px) {
  .history-table-heading {
    font-size: 20px;
  }
  .history-table-description {
    font-size: 12px;
    margin: 10px 0px 10px 0px;
  }
  .history-table-row-heading {
    font-size: 13px;
  }
  .history-table-inner-contents {
    font-size: 12px;
  }
  .address-hash-in-transaction-table {
    font-size: 12px;
  }
}
