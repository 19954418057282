#price-card-id {
  color: #8b8b8b;
  font-size: 1rem;
  margin-top: 0.7rem;
  margin-left: 0.7rem;
}

#price-card-value {
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-price-card-styler-second-flex-title {
  color: #165F48;
  font-size: 15px;
  margin-bottom: 0;
}

p {
  margin: 0;
}
.price-card-styler {
  background: white;
  min-width: 230px;
  width: fit-content;
  height: 85px;
  border-radius: 8px;
  margin-bottom: 1rem;
  display: flex;
  box-shadow: 4px 4px 30px rgba(27, 62, 105, 0.16);
}

.new-price-card-styler {
  width: 30%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.new-price-card-styler img {
  height: 45px;
}

.new-price-card-styler-second-flex {
  width: 200px;
  height: 94px;
  padding: 7px 0 5px 20px;
}

.price-card-dollar-image-resizer {
  height: 1.2rem;
}

.price-and-usd-texting-styling {
  display: flex;
  gap: 5px;
  justify-content: flex-start;
  /* margin-top:-10px; */
}
.price-and-usd-texting-styling p {
  font-size: 1.25rem;
}
.usd-price-value-styling {
  color: #165F48;
  font-size: 2rem;
  font-weight: bold;
  white-space: nowrap;
  overflow: clip;
  text-overflow: ellipsis;
  max-width: 210px;
}
.usd-symbol-card-styling {
  color: #6d7681;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 5px;
  padding-left: 5px;
}
.empty-div-for-percentage {
  height: 22px;
}

/* @media only screen and (max-width: 1700px){
    .price-card-styler{
        height:85px;
        width:230px;
    }
    .new-price-card-styler-second-flex{
        padding:7px 0px 5px 20px;
    }
    .new-price-card-styler-second-flex-title{
        font-size: 16px;
    }
    .price-and-usd-texting-styling p{
        font-size:15px;
    }
    .usd-price-value-styling{
        font-size: 25px;
    }
    .usd-symbol-card-styling{
        font-size: 15px;
    }
    .empty-div-for-percentage{
        height:10px;
    }
} */
