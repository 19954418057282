.strategyHeading {
  font-size: 24px;
  font-family: Catamaran;
  color: #1b3e69;
  font-weight: bold;
  margin-bottom: 5px;
}

.active-strategy-container-renderer {
  width: 49vw;
  min-height: 681px;
  padding: 25px 40px;
  background-color: white;
  border-radius: 0px 8px 8px 0px;
}

.strategyDescription {
  width: 19.270833333333332vw;
  font-size: 14px;
  text-align: justify;
}

.indivigual-strategy-main-styler {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}

/* style={{display:"flex",width:"19.270833333333332vw",justifyContent:"space-between",marginTop:"5px",paddingBottom:"5px",borderBottom:"1px solid #DEE5EF"}} */
.indivigual-strategy-indivigual-content {
  display: flex;
  width: 19.270833333333332vw;
  justify-content: space-between;
  margin-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #dee5ef;
}

@media only screen and (max-width: 1641px) {
  .strategyHeading{
    font-size:20px;
  }
  .strategyDescription{
      font-size:12px;
  }
  
}

@media only screen and (max-width: 1550px) {
 
  .indivigual-strategy-indivigual-content {
    width: 18.5vw;
  }
}

@media only screen and (max-width: 1450px) {
  .indivigual-strategy-main-styler {
    flex-direction: column;
    gap: 5px;
  }
  .indivigual-strategy-indivigual-content {
    width: 25vw;
  }
}
