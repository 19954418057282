.custom-add-button-styling{
    display:flex;
    background-color: #DEE5EF;
    border:0px;
    border-radius: 4px;
    padding:4px 16px 4px 12px;
    transition: 0.3s all ease;

}

.custom-add-button-styling p{
    font-size: 14px;
    font-weight: bold;
    color:#1B3E69;
    padding-left: 4px;
}

.custom-add-button-styling:hover{
    background-color: #cdd0d4;
    cursor: pointer;
}


.custom-add-button-styling img{
    margin-top: 2px;
}


@media only screen and (max-width: 1641px) {
    .custom-add-button-styling p{
      font-size:12px;
    }
    .custom-add-button-styling img{
        margin-top: 1px;
    }
    .custom-add-button-styling{
        padding:4px 12px 4px 8px;
    }
   
  }