

.dashboard-deposit-main-card{
    height:575px;
    width:586px;
    border-radius: 8px 8px 8px 8px;
    background-color:white;
}

.Deposit-Heading{
    width:100%;
    height:48px;
    border-radius:8px 8px 0px 0px;
    display: flex;
    align-items: center;
    background-color: #164F58;
}
.Deposit-Heading p{
    color:white;
    font-size: 20px;
    padding-left: 30px;
    font-weight: bold;

}

.assets-and-interest-apr{
    display:flex;
    height:50px;
    border-bottom: 1px solid #DEE5EF;
    padding-left:20px;
    gap:20px;
}

.deposit-modal-asset-flexbox,.deposit-modal-interest-apr-flexbox{
    display:flex;
    width:32%;
    gap:20px;
    justify-content: center;
    align-items: center;
 }

 .deposit-modal-text p{
    font-size: 14px;
    color:#6D7681;
   
}

.asset-select-dropdown-div{
    padding-left: 42px;
    display: flex;
    height: 80px;
    align-items: center;
    gap:125px;
    border-bottom: 1px solid #DEE5EF;
}

.input-text-and-shares{
    height:140px;
    border-bottom: 1px solid #DEE5EF;
    display: flex;
    align-items: center;
    padding-left: 42px;
    /* gap:110px; */
    margin-top: 10px;
}

.deposit-button-styling{
    display:flex;
    justify-content:space-around;
    gap:17px;
    width:294px;
}

.deposit-button-styling button{
    padding:7px 15px;
    border:none;
    border-radius:5px;
    background:#DEE5EF;
    color:#6D7681;
    cursor:pointer;
    font-size:1rem;
    font-weight:bold;
}

.deposit-button-styling button p{
    color:#6D7681;
    font-size: 14px;
    font-weight: bold;

}

.dashboard-deposit-main-card .deposit-modal-column-1{
    background-color:white;
    width:30%;
    height:100%;
    display:flex;
    justify-content:center;
    align-items:center;
}

 .dashboard-deposit-main-card .deposit-modal-column-2{
    background-color:#0E2139;
 }

 .deposit-modal-asset-image-resizer{
     height:6rem;
 }

 

 .assets-and-apr-deposit-modal{
     margin-top:30px;
     height: 35px;
 }
    
 .assets-and-apr-deposit-modal{
     display:flex;
     justify-content: space-around;
     gap:105px;
    
 }

 .gas-and-vault-alloc-numeric-data{
     height:170px;
     border-bottom: 1px solid #DEE5EF;
     display: flex;
     padding: 0px 42px;
     width:100%;
 }

 .gas-and-vault-alloc-numeric-data-detailed-info{
     /* line-height: 2px; */
     width:53%;
     display: flex;
     flex-direction: column;
     gap:3px;
 }

 .gas-vault-alloc-total-styling{
     font-size: 18px;
     font-weight: bold;
     color: #1B3E69;
     padding-top: 10px;
 }


 .submit-button-deposit-modal button{
     border:none;
     padding:12px 100px;
     background:#22c55e;
     border-radius:8px;
     color:white;
     font-size:1.2rem;
     font-weight:bold;
     cursor:pointer; 
     transition:0.3s all ease;
 }

 .submit-button-deposit-modal button p{
     color:white;
     font-size: 20px;
 }

 .submit-button-deposit-modal .disabled{
    border:none;
    padding:12px 100px;
    background:#164F58;
    border-radius:8px;
    color:white;
    font-size:1.2rem;
    font-weight:bold;
    cursor:not-allowed; 
    transition:0.3s all ease;
}

 .submit-button-deposit-modal {
    display: flex;
    justify-content: flex-end;
    padding-right: 40px;
    padding-top: 12px;
 }

 .colour-white{
     color:white;
     cursor:pointer;
 }

 .balance-held-by-user{
     display:flex;
     gap:10px;
     justify-content: flex-end;
     color:white;
     font-weight:bold;
 }

 .balance-held-by-user p{
     font-size:12px;
 }

 #less-balance-alert-text-deposit {
     color:#C42121;
     font-size:14px;
     width:75%;
 }

 





 /* #00C0AB */
 