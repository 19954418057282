.sidebar-content-renderer{
    min-height:638px;
    width: 75%;
padding: 25px 40px;
background-color: white;
border-radius: 0px 8px 8px 0px;
}

.transaction-title-text{
    font-family: Catamaran;
    font-size:36px;
    color:#1B3E69;
}


.transaction-component-aligner{
    padding:15px 0px 0px 30px;
    width: 65.91vw;
    /* height:100vh; */
}


/* .main-transaction-component{
    width:51.5625 !important;
    background-color: red !important;
    border-radius: 8px 0px 0px 8px !important;
    box-shadow: #1B3E69 !important;
} */

@media only screen and (max-width: 1640px) {
  .transaction-title-text{
    font-size: 30px;
  }
}

@media only screen and (max-width: 1720px) {
    .transaction-component-aligner{
      width:63.91vw;
     }
  }
  
  @media only screen and (max-width: 1618px) {
    .transaction-component-aligner{
      width:61.91vw;
     }
  }
  
  @media only screen and (max-width: 1516px) {
    .transaction-component-aligner{
      width:59.91vw;
     }
     
   .investments_and_vault_stats {
    width: 265px;
  }
  }
  
  @media only screen and (max-width: 1446px) {
    .transaction-component-aligner{
      width:57.91vw;
     }
     .investments_and_vault_stats {
      width: 265px;
    }
  }
  
  @media only screen and (max-width: 1380px) {
    .transaction-component-aligner{
      width:55vw;
      padding-left:15px;
     }
  }
  
  @media only screen and (max-width: 1279px) {
    .transaction-component-aligner{
      width:69vw;
     }
     .investments_and_vault_stats {
      width: 295px;
    }
  }
  
  @media only screen and (max-width: 1150px) {
    .transaction-component-aligner{
      width:66vw;
     }
  }