.active-strategy-component-aligner{
    min-height:681px;
    padding:15px 0px 0px 30px;
   width:65.91vw;
}

.active-strategy-component-renderer{
    width:75%;
    padding: 25px 40px;
    background-color: white;
    border-radius: 0px 8px 8px 0px;
    /* box-shadow: 4px 4px 30px -4px rgba(27, 62, 105, 0.16); */
}

@media only screen and (max-width: 1720px) {
    .active-strategy-component-aligner{
      width:63.91vw;
     }
  }
  
  @media only screen and (max-width: 1618px) {
    .active-strategy-component-aligner{
      width:61.91vw;
     }
  }
  
  @media only screen and (max-width: 1516px) {
    .active-strategy-component-aligner{
      width:59.91vw;
     }
     
   .investments_and_vault_stats {
    width: 265px;
  }
  }
  
  @media only screen and (max-width: 1446px) {
    .active-strategy-component-aligner{
      width:57.91vw;
     }
     .investments_and_vault_stats {
      width: 265px;
    }
  }
  
  @media only screen and (max-width: 1380px) {
    .active-strategy-component-aligner{
      width:55vw;
      padding-left:15px;
     }
  }
  
  @media only screen and (max-width: 1279px) {
    .active-strategy-component-aligner{
      width:69vw;
     }
     .investments_and_vault_stats {
      width: 295px;
    }
  }
  
  @media only screen and (max-width: 1150px) {
    .active-strategy-component-aligner{
      width:66vw;
     }
  }