#loading {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f8fafd;
  }
  
  #akkrue-logo {
    height: 10vh;
  }
  
  .requestProgress {
    margin-top: 30px;
    width: 10%;
    height: 2px;
    position: relative;
    overflow: hidden;
    background: #165f48;
  }
  
  .bar {
    position: absolute;
    width: calc(100% * 2 / 7);
    height: 100%;
    display: flex;
    -webkit-animation: move 1s linear infinite;
    animation: move 1s linear infinite;
    -moz-animation: move 1s linear infinite;
    -ms-animation: move 1s linear infinite;
    background-color: white;
  }
  
  @keyframes move {
    0% {
      transform: translate(-100%, 0);
    }
    100% {
      transform: translate(calc(7 / 2 * 100%), 0);
    }
  }
  
  
  @-webkit-keyframes move {
    0% {
      transform: translate(-100%, 0);
    }
    100% {
      transform: translate(calc(7 / 2 * 100%), 0);
    }
  }
  
  @-moz-keyframes move {
    0% {
      transform: translate(-100%, 0);
    }
    100% {
      transform: translate(calc(7 / 2 * 100%), 0);
    }
  }
  