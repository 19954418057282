.admin-page-wrap {
  margin-top: 80px;
  margin-left: auto;
  margin-right: auto;

  width: fit-content;
}
.admin-page-card-wrap {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 2fr 2fr 2fr;
  grid-gap: 20px;
}
.explore-vaults-admin {
  width: 1024px;
}

