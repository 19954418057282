.custom-tooltip {
  padding: 10px;
  background-color: #0e2139;
  /* border: 2px solid #c3c3c3; */
  border-radius: 10px;
  color: white;
}

.custom-tooltip p {
  color: white;
  font-family: Catamaran;
}

.custom-tooltip span {
  font-weight: bold;
}
