.custom-loader-animation-main-div{
    margin-top: 15px;
    width: 100px;
    height: 2px;
    position: relative;
    overflow: hidden;
    background: #165f48;
}

.custom-loader-animation-main-sub-div {
    position: absolute;
    width: calc(100% * 2 / 7);
    height: 100%;
    display: flex;
    -webkit-animation: move 1s linear infinite;
    animation: move 1s linear infinite;
    -moz-animation: move 1s linear infinite;
    -ms-animation: move 1s linear infinite;
    background-color: white;
  }
  
  @keyframes move {
    0% {
      transform: translate(-100%, 0);
    }
    100% {
      transform: translate(calc(7 / 2 * 100%), 0);
    }
  }
  
  
  @-webkit-keyframes move {
    0% {
      transform: translate(-100%, 0);
    }
    100% {
      transform: translate(calc(7 / 2 * 100%), 0);
    }
  }
  
  @-moz-keyframes move {
    0% {
      transform: translate(-100%, 0);
    }
    100% {
      transform: translate(calc(7 / 2 * 100%), 0);
    }
  }

  #loading-text{
      color:#165f48;
      font-family:Catamaran;
      font-weight:bold;
      font-size: 20px;
  }
  