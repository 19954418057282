/* *{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */
body {
  background-color: #f8fafd;
  height: 100vh;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #0e2139 #dee5ef;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background: #dee5ef;
}

body::-webkit-scrollbar-thumb {
  background: #0e2139;
  border-radius: 8px;
  height: 20px;
}

.toast-notification-enter {
  opacity: 0;
  transform: scale(0.9);
}
.toast-notification-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.toast-notification-exit {
  opacity: 1;
}
.toast-notification-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
.bn-onboard-modal {
  z-index: 1100;
}
.txt-brown {
  color: #22c55e;
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
}

.txt-primary {
  color: #164F58;
}

a.link {
  font-weight: 500;
  color: #164F58 !important;
  text-decoration: underline !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.description {
  padding: 10px 0px 20px;
  font-size: 14px;
}

.main-heading {
  font-size: 24px;
}

.secondary-heading {
  font-size: 18px;
  padding-bottom: 10px;
}

.main-heading,
.secondary-heading,
.page-content {
  color: #1b3e69;
  font-family: Catamaran;
}
.main-heading,
.secondary-heading {
  font-weight: bold;
}

.page-content {
  font-size: 15px;
  font-weight: normal;
}

.sub-heading {
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 8px;
  font-family: Catamaran;
  color: #1b3e69;
}

.subtitle {
  padding-bottom: 8px;
  display: block;
}

.inline-toggle-item {
  text-align: left;
  min-height: 50px;
  display: flex;
  align-items: flex-end;
  gap: 30px;
}

.inline-toggle-item span.text {
  padding-top: 3px !important;
}

.inline-toggle-item button {
  transform: scale(1.3);
}

.inline-toggle-item .ant-switch-checked {
  background-color: #164F58;
}
.custom-select-field
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-item {
  font-size: 14px;
  font-family: "Catamaran";
  font-weight: 600;
  color: #164F58;
  line-height: 42px;
}
.custom-select-field .ant-avatar img,
.ant-select-item-option-content .ant-avatar img {
  height: 20px;
  width: auto;
}
.custom-select-field
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: #6d7681;
  border-radius: 2px;
}
.custom-select-field .validation-required {
  right: 15px !important;
}