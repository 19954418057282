@import "~antd/dist/antd.css";

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}
p {
  margin-top: 0;
  margin-bottom: 0;
}

.main-wrapper {
  height: 100vh;
  display: grid;
  grid-template-rows: 60px auto 100px;
}

.main-wrapper .container {
  background: #f8fafd;
  width: 100%;
}

.main-wrapper .wrapper {
  background: #f8fafd;
  padding-top: 60px;
}

#exploreVault .baseHeading {
  text-align: center;
  padding-bottom: 15px;
}

#exploreVault .baseHeading h1 {
  color: #164F58;
  margin-bottom: 8px;
  font-family: "Catamaran";
  font-weight: 700;
  font-size: 36px;
}

#exploreVault .baseHeading p {
  color: #6d7681;
  font-size: 15px;
  line-height: 1.7;
  padding: 0px 7%;
  font-family: "Open Sans";
}

.main {
  padding-top: 35px;
}

.main-items {
  display: grid;
  grid-template-columns: 25% 73%;
  gap: 25px;
}

.main-wrapper .footer {
  display: flex;
  flex-direction: row-reverse;
  padding: 40px 0 20px;
}

.footer > .footer-items {
  width: 840px;
  height: 19px;
  text-align: right;
  font: normal normal normal 14px/32px "Open Sans";
  letter-spacing: 0px;
  color: #1b3e69;
  opacity: 1;
  margin: 0;
}

.footer-items span {
  padding: 0 1%;
}

.tabCardData .ant-tabs-nav-wrap {
  justify-content: center;
}

.tabCardData .ant-tabs-nav:before {
  border: none !important;
}

.tabCardData .ant-tabs-tab-active .ant-tabs-tab-btn {
  background-color: #dee5ef;
  color: #1b3e69 !important;
  text-shadow: 0 0 1px #1b3e69 !important;
}

.tabCardData .ant-tabs-tab-btn {
  font-size: 13px;
  font-family: "Open Sans" !important;
  color: #1b3e69;
  text-transform: uppercase;
  padding: 8px 15px;
  border-radius: 8px;
}

.tabCardData .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 20px;
}

.tabCardData .ant-tabs-ink-bar {
  display: none;
}

.searchvault {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.searchvault form {
  display: flex;
}

.searchvault .formElementz {
  min-width: 295px;
  margin-right: 25px;
}

.searchvault .formElementz label {
  font-size: 13px;
  font-family: "Open Sans";
  font-weight: 700;
  color: #164F58;
}

.searchvault .formElementz .ant-form-item {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.searchvault .formElementz .ant-form-item .ant-form-item-label {
  text-align: left;
}

.tabCardData .searchvault .formElementz .termSearch,
.searchvault .ant-select-selector {
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  height: 52px !important;
  border-radius: 4px !important;
  background-color: transparent !important;
  outline: none !important;
  box-shadow: none !important;
}

.searchvault input .termSearch {
  padding-left: 35px;
}

.termSearch .ant-input-group .ant-input-group-addon {
  display: none;
}

.searchvault .termSearch .ant-input-affix-wrapper {
  padding: 0px 11px;
  background-color: transparent;
  border: none;
}

.searchvault .termSearch .ant-input-affix-wrapper input {
  padding: 0px 11px;
  background-color: transparent;
  border: none;
}

.searchvault .ant-select-selection-search-input {
  height: 100% !important;
}

.searchvault .ant-select-selection-placeholder {
  line-height: 50px !important;
  color: #adaeb0;
  font-size: 14px;
  font-family: "Roboto";
}

.searchvault input,
.searchvault .ant-select-selection-item {
  font-size: 15px;
  font-family: "Roboto";
  color: #164F58;
  line-height: 50px !important;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #adaeb0;
  font-size: 14px;
  font-family: "Roboto";
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #adaeb0;
  font-size: 14px;
  font-family: "Roboto";
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #adaeb0;
  font-size: 14px;
  font-family: "Roboto";
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #adaeb0;
  font-size: 14px;
  font-family: "Roboto";
}

.tabCardData .ant-tabs {
  overflow: unset;
}

.ant-select-item-option-selected {
  background-color: #dee5ef !important;
}

.searchvault .ant-form-item-control-input {
  position: relative;
}

.searchvault .SearchIn {
  position: absolute;
  top: 14px;
  left: 8px;
  width: 20px;
}

.searchvault .SearchIn img {
  width: 100%;
}

.searchvault .ant-select-arrow {
  position: relative;
}

.searchvault .ant-select-arrow {
  content: "";
  position: absolute;
  top: 22px;
  right: 6px;
  background-image: url(../../../assets/icons/downarrow.svg);
  width: 20px;
  height: 16px;
  background-repeat: no-repeat;
  background-size: cover;
}

.tabCardData .ant-tabs-content {
  display: table;
}

.leftSearch .ant-form-item {
  margin-bottom: 0;
}

.switchRadio svg {
  width: 28px;
}

.switchRadio .grid-view svg {
  width: 25px;
}

.switchRadio .list-view svg {
  width: 25px;
}

.switchRadio .chart-view svg {
  height: fit-content;
  width: 38px;
}

.switchRadio label {
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 6px 11px;
  height: 44px;
  margin: 0 4px;
  border-radius: 4px !important;
  background-color: transparent;
}

.switchRadio label:nth-of-type(3) {
  border: 1px solid rgba(0, 0, 0, 0.12);
  height: 44px;
  border-radius: 4px !important;
  background-color: transparent;
  padding: 2px 8px;
}

.switchRadio .ant-radio-group,
.RightSearch {
  display: flex;
}

.switchRadio {
  align-self: flex-end;
}

.switchRadio > span {
  display: block;
  font-size: 13px;
  font-family: "Open Sans";
  font-weight: 700;
  color: #164F58;
  padding-left: 4px;
  margin-bottom: 6px;
}

.grid-view-active .grid-view .home-icon g line {
  stroke: #ffffff;
}

.list-view-active .list-view .home-icon g line {
  stroke: #ffffff;
}

.chart-view-active .chart-view .home-icon g line {
  stroke: #ffffff;
}

.chart-view-active .chart-view .home-icon circle {
  stroke: #ffffff;
}

.grid-view-active label:nth-of-type(1) {
  background-color: #1b693e;
}

.list-view-active label:nth-of-type(2) {
  background-color: #1b693e;
}

.chart-view-active label:nth-of-type(3) {
  background-color: #1b693e;
}

.mainDataWrap .tabCardData .ant-spin-lg .ant-spin-dot {
  margin-top: 220%;
}
