.dashboard-withdraw-main-card {
  height: 570px;
  width: 586px;
  background-color: white;
  border-radius: 8px;
}

.Withdraw-Heading {
  width: 100%;
  height: 48px;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  align-items: center;
  background-color: #164F58;
}

.Withdraw-Heading p {
  color: white;
  font-size: 20px;
  padding-left: 30px;
  font-weight: bold;
}

.input-text-and-shares-withdraw {
  height: 130px;
  border-bottom: 1px solid #dee5ef;
  display: flex;
  align-items: center;
  padding-left: 38px;
  gap: 5px;
}

.withdraw-button-styling {
  display: flex;
  justify-content: space-around;
  gap: 17px;
  width: 294px;
}

.withdraw-button-styling button {
  padding: 7px 15px;
  border: none;
  border-radius: 5px;
  background: #dee5ef;
  color: #6d7681;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
}

.withdraw-button-styling button p {
  color: #6d7681;
  font-size: 14px;
  font-weight: bold;
}

.display-token-balance {
  display: flex;
  height: 50px;
  border-bottom: 1px solid #dee5ef;
  padding-left: 38px;
  align-items: center;
  gap: 20px;
}

.asset-select-dropdown-div-withdraw {
  padding-left: 38px;
  display: flex;
  height: 70px;
  align-items: center;
  gap: 125px;
  border-bottom: 1px solid #dee5ef;
}

.gas-and-vault-alloc-numeric-data-withdraw {
  height: 180px;
  border-bottom: 1px solid #dee5ef;
  display: flex;
  padding: 0px 40px;
  width: 100%;
}

.gas-and-vault-alloc-numeric-data-detailed-info-withdraw {
  /* line-height: 2px; */
  width: 53%;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.gas-vault-alloc-total-styling-withdraw {
  font-size: 18px;
  font-weight: bold;
  color: #1b3e69;
  padding-top: 10px;
}

#less-balance-alert-text-withdraw {
  color: #c42121;
  font-size: 14px;
  width: 75%;
}

.submit-button-withdraw-modal button,
.submit-button-withdraw-modal .disabled {
  border: none;
  padding: 15px 90px;
  background: #164F58;
  border-radius: 8px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: 0.3s all ease;
}

.submit-button-withdraw-modal .disabled {
  background: #164F58;
  cursor: not-allowed;
  transition: 0.3s all ease;
}

.submit-button-withdraw-modal button p,
.submit-button-withdraw-modal .disabled {
  color: white;
  font-size: 20px;
}
