.registration-login-wrap {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 1fr 2fr;
  justify-content: center;
}
.registration-login-wrap .logo {
  width: 136px;
  height: 72.55px;
  margin: 20px auto 30px auto;
}

.registration-login-wrap .registration-login-wrap-content-wrap {
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  box-shadow: 4px 4px 30px rgb(27 62 105 / 10%);
  border-radius: 8px;
}
