.description-editor-box {
  width: 100%;
}

.description-editor-box iframe {
  display: block;
}

.description-container {
  padding-top: 20px;
  width: 100%;
  display: grid;
  gap: 25px;
}
.description-container .left-block-wrap {
  display: flex;
  position: relative;
  text-align: center;
  width: auto;
  flex-direction: row;
  justify-content: center;
}
.description-container .left-block-wrap .go-back {
  width: fit-content;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #22c55e;
  display: flex;
  flex-direction: row;
  height: fit-content;
  cursor: pointer;
}
.description-container .right-block-wrap {
  padding-right: 25px;
}
.description-container .right-block-wrap .heading {
  font-family: "Open Sans";
  font-style: normal;
  color: #164f58;
}
.description-container .right-block-wrap .heading h2 {
  font-family: "Open Sans";
  font-style: normal;
  color: #164f58;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 10px;
}
.description-container .right-block-wrap .heading p {
  font-family: "Open Sans";
  font-style: normal;
  color: #164f58;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 30px;
}
.description-container .right-block-wrap .button-wrap {
  flex-direction: row;
  justify-content: flex-end;
  gap: 20px;
  padding: 6% 1%;
}
.description-container .right-block-wrap .button-wrap button {
  width: auto;
  font-size: 16px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  line-height: 22px;
  padding: 0px 20px;
  cursor: pointer;
}
.description-container .button-wrap .button-content-invert {
  border: 1px solid #22c55e;
  border-radius: 6px;
  color: #22c55e;
}
.description-popover {
  width: 100vw;
  z-index: 1000;
  height: 75vh;
}
.description-popover .ant-popover-content {
  position: sticky;
}
.description-popover .ant-popover-title {
  background-color: #164f58;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  height: 54px;
  vertical-align: middle;
}
.description-popover .ant-popover-title .description-popover-title {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.description-popover .description-popover-title .close-button {
  cursor: pointer;
}
.description-popover .description-popover-title .close-button:hover {
  color: #22c55e;
}
.description-popover .ant-popover-arrow {
  display: none;
}
.description-popover .break-line {
  height: 0.25px;
  background-color: #164f58;
  width: 100%;
  margin: 20px 0px;
}
.description-container .preview-wrapper {
  display: block;
}
.preview-wrapper .back-and-preview {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
}
.preview-wrapper .back-and-preview .back {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #22c55e;
  display: flex;
  cursor: pointer;
}
.preview-wrapper .back-and-preview .preview span {
  padding: 2px 15px;
  background: #164f58;
  border-radius: 22px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
}
.preview-wrapper .preview-text-wrapper {
  width: 100%;
  background: #f8fafd;
  padding: 2% 6%;
}
.preview-wrapper .preview-text-wrapper .dangerously-set {
  background: #ffffff;
  padding: 3%;
}
/* .tox-tinymce-aux {
  position: fixed !important;
  top: 200px !important;
} */
