.row-one-in-dashboard {
  display: flex;
}

body {
  background-color: #f8fafd !important;
}

.investments_and_vault_stats {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 295px;
}
.dashboard-title-styling {
  font-size: 36px;
  font-family: Catamaran;
  color: #164F58;
}

.dashboard-style-main-component {
  padding: 1% 0px 0px 30px;
  width: 65.91vw;
  /* width: 60; */
}

.empty-div-component {
  height: 104px;
}
@media only screen and (max-width: 1640px) {
  .empty-div-component {
    height: 94px;
  }
}
@media only screen and (max-width: 1560px) {
  .investments_and_vault_stats {
    width: 285px;
  }
}

@media only screen and (max-width: 1280px) {
  .investments_and_vault_stats {
    width: 295px;
  }
}

/* @media only screen and (max-width: 1723px) {
  .dashboard-style-main-component {
    width: 61.91vw;
  }
} */

@media only screen and (max-width: 1720px) {
  .dashboard-style-main-component {
    width: 63.91vw;
  }
}

@media only screen and (max-width: 1618px) {
  .dashboard-style-main-component {
    width: 61.91vw;
  }
}

@media only screen and (max-width: 1516px) {
  .dashboard-style-main-component {
    width: 59.91vw;
  }

  .investments_and_vault_stats {
    width: 265px;
  }
}

@media only screen and (max-width: 1446px) {
  .dashboard-style-main-component {
    width: 57.91vw;
  }
  .investments_and_vault_stats {
    width: 265px;
  }
}

@media only screen and (max-width: 1380px) {
  .dashboard-style-main-component {
    width: 55vw;
    /* padding-left: 15px; */
  }
}

@media only screen and (max-width: 1279px) {
  .dashboard-style-main-component {
    width: 69vw;
  }
  .investments_and_vault_stats {
    width: 295px;
  }
}

@media only screen and (max-width: 1150px) {
  .dashboard-style-main-component {
    width: 66vw;
  }
}

@media only screen and (max-width: 1640px) {
  .dashboard-title-styling {
    font-size: 30px;
  }
}
