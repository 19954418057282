.assets-debts-collapse-style {
  background-color: #dee5ef;
  margin: 0px;
  height: 130px;
  padding: 20px 4.166666666666667vw;
}

.table-header-styling {
  background-color: #dee5ef;
  border-radius: 0px;
}

.table-header-styling p {
  color: #1b3e69;
  font-weight: bold;
  font-size: 14px;
}

.table-header-styling .titleHeading{
  font-size: 16px;
}

.assets-debts-table-contents {
  border-bottom: 1px solid #dee5ef;
}

.assets-debts-table-contents p {
  font-size: 14px;
  font-weight: bold;
}

@media only screen and (max-width: 1640px) {
  .table-header-styling p {
    font-size: 13px;
  }
  .table-header-styling .titleHeading{
    font-size: 14px;
  }
  .assets-debts-table-contents p {
    font-size: 12.5px;
  }
}
