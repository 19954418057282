@import "~antd/dist/antd.css";

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

.collapse .panel {
  font: normal normal bold 10px/14px Open Sans;
  letter-spacing: 0px;
  color: #6d7681;
  opacity: 1;
}

/* .panel.ant-collapse-item > .ant-collapse-header div {
   position: fixed;
}
.panel.ant-collapse-item > .ant-collapse-header div#head {
  position: fixed; 
  padding-left: 2%; 
} */

.collapse .panel .contents {
  display: grid;
  grid-template-columns: repeat(5, auto);
  gap: 10px;
  max-height: 250px;
  overflow-y: auto;
}

.collapse .panel .logo {
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.collapse .panel .logo img {
  height: 25px;
  width: 25px;
  border: none;
}

.collapse .panel .logo span {
  border: 2px solid pink;
  border-radius: 35%;
}

.collapse .panel .list {
  display: grid;
  grid-template-rows: auto;
  gap: 20px;
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
}

.collapse .panel .list-items {
  display: grid;
  grid-template-columns: 20% 70% 10%;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #6d7681;
  align-self: center;
  width: 80%;
  cursor: url(https://ssl.gstatic.com/ui/v1/icons/mail/images/2/openhand.cur), default;
}

.search-result {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 10px;
}

.search-result .title {
  text-align: left;
  text-decoration: underline;
  font: normal normal bold 10px/14px Open Sans;
  letter-spacing: 0px;
  color: #6d7681;
  opacity: 1;
}

.search-result .contents {
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: space-between;
  align-items: center;
  padding: 5% 1%;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
}

.search-result .contents span {
  cursor: grabbing;
}

.search-result .contents p {
  text-transform: capitalize;
  margin: 0;
  text-decoration: underline dotted #6d7681 0.5px;
  color: #6d7681;
}

/* new changes */
.list-text,
.list-right-icon {
  display: flex;
  align-items: center;
}

.list-text {
  font-family: "Catamaran",sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #000000;
  padding-left: 8px;
}

.ant-collapse-header #head {
  font-family: "Catamaran",sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #164f58;
  text-align: left;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  line-height: 1 !important;
  padding: 12px 16px 12px 0;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  font-size: 15px;
  color: #6d7681;
  font-weight: 600;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 12px 20px;
}
