*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: #1b3e69;
}

.container {
  background: #f8fafd;
  width: 100%;
}

.create-vault-container {
  padding: 0px 15px;
  max-width: 1170px;
  margin: 0 auto;
}

.wrapper {
  display: block;
  background: #f8fafd;
  padding-top: 60px;
}

.baseHeading {
  text-align: center;
  padding-bottom: 15px;
}

.baseHeading h1 {
  color: #164f58;
  margin-bottom: 8px;
}

.create-vault2 .baseHeading h1 {
  color: #164f58;
  margin-bottom: 8px;
  font-family: "Catamaran";
  font-weight: 700;
  font-size: 36px;
}

.baseHeading p {
  color: #6d7681;
  font-size: 15px;
  line-height: 1.7;
  padding: 0px 7%;
}

.main {
  padding-top: 45px;
}

.main-items {
  display: grid;
  grid-template-columns: 25% 73%;
  gap: 25px;
}

.main-items .left-block-wrap {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: fixed;
  position: -webkit-sticky;
  width: min-content;
}

.main-items .left-block-wrap .toggle-view {
  display: grid;
  grid-template-columns: 50% 50%;
}

.main-items .left-block-wrap .toggle-view button {
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: transparent;
  text-align: left;
  font-weight: bold;
}

.main-items .left-block-wrap .toggle-view .list {
  font-weight: bold;
  color: #164f58;
}

.main-items .left-block-wrap .toggle-view .grid {
  color: #1b3e69;
}

.main-items .left-block-wrap .search {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 194.72px;
  row-gap: 10px;
}

.main-items .left-block-wrap .search h4 {
  text-align: left;
  word-break: normal;
  font-family: "Catamaran";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 13px;
  color: #22c55e;
}

.main-items .left-block-wrap .search .search-input {
  display: flex;
  justify-content: flex-start;
  opacity: 1;
  box-sizing: border-box;
  width: 190.41px;
  background: #f8fafd;
  border: 1px solid #c4c4c4;
  border-radius: 2px;
  padding: 0;
  margin: 0;
  height: 38px;
}

.main-items
  .left-block-wrap
  .search
  .search-input
  .ant-input-group
  .ant-input-group-addon {
  display: none;
}

.main-items .left-block-wrap .search .search-input .ant-input-affix-wrapper {
  padding: 0px 11px;
  height: 38px;
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.main-items
  .left-block-wrap
  .search
  .search-input
  .ant-input-affix-wrapper
  input {
  padding: 0px 11px;
  height: 38px;
  background-color: transparent;
  border: none;
}

.main-items .left-block-wrap .search .autocomplete-data {
  overflow-y: auto;
  text-align: left;
  padding-left: 20px;
  list-style: none;
  box-shadow: 4px 4px 30px rgb(27 62 105 / 16%);
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  background-color: #fff !important;
  max-height: 250px !important;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.main-items .left-block-wrap .search .autocomplete-data li span {
  cursor: copy;
}

.footer {
  display: flex;
  flex-direction: row-reverse;
  padding: 40px 0 20px;
  background: #f8fafd;
}

.footer > .footer-items {
  width: 840px;
  height: 19px;
  text-align: right;
  font: normal normal normal 14px/32px "Open Sans";
  letter-spacing: 0px;
  color: #164f58;
  opacity: 1;
  margin: 0;
}

.footer-items span {
  padding: 0 1%;
}

.vaultWrap {
  display: flex;
  justify-content: space-between;
}
.vaultwarpBox {
  flex: 0 0 65%;
  max-width: 65%;
}
.vaultwrapProgress {
  flex: 0 0 25%;
  max-width: 25%;
}
.vaultcontainer {
  display: flex;
  height: 100%;
}
.vaultGenerator {
  background-color: #dee5ef;
  height: 370px;
  width: calc(100% - 24px);
}
.vaultGenerator {
  align-self: center;
  padding: 25px 12px;
}
.vaultData {
  text-align: center;
  height: 100%;
  justify-content: space-between;
  flex-direction: column;
  display: flex;
}
.vaultData h2 {
  line-height: 1.1;
  font-size: 26px;
  color: #1b3e69;
  font-family: "Catamaran";
  font-weight: 600;
}
.vaultStage p {
  font-size: 14px;
  color: #526d8e;
  line-height: 1.1;
  font-weight: 500;
  font-family: "Open Sans";
  margin: 0;
}
.vaultStage span {
  display: block;
}
.vaultConnectors {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  flex-direction: column;
  align-self: center;
  height: 370px;
}
.vaultConnectors span {
  background-color: #dee5ef;
  height: 55px;
  width: 24px;
  position: relative;
}

.vaultConnectors .active1 {
  background-color: #164f58;
}
.vaultConnectors .active2 {
  background-color: #22c55e;
}
.vaultConnectors .active3 {
  background-color: #164f58;
}
.vaultConnectors .active4 {
  background-color: #22c55e;
}

.vaultConnectors span:nth-of-type(1) svg {
  position: absolute;
  top: -155px;
  left: -83px;
  height: 196px;
  overflow: visible;
}
.vaultConnectors span:nth-of-type(2) svg {
  position: absolute;
  top: -45px;
  left: -83px;
  height: 86px;
  overflow: visible;
}
.vaultConnectors span:nth-of-type(3) svg {
  position: absolute;
  top: 136px;
  left: -192px;
  height: 86px;
  transform: rotate(90deg);
  overflow: visible;
}
.vaultConnectors span:nth-of-type(4) svg {
  position: absolute;
  top: 136px;
  left: -192px;
  height: 86px;
  transform: rotate(90deg);
  overflow: visible;
}

.main-items .right-block-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.right-block-wrap .button-wrap {
  display: flex;
  flex-direction: row-reverse;
  padding: 5%;
}

.right-block-wrap .button-content {
  height: 61px;
  width: 278px;
  background: #22c55e 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  text-align: center;
  font: normal normal bold 18px/30px Catamaran;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 1;
  cursor: pointer;
  border: none;
}

.right-block-wrap .button-wrap .button-content:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

/* Disclaimer */
.disclaimer-wrapper .ant-col {
  background: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 4px -1px 25px -1px rgb(27 62 105 / 10%);
}

.disclaimer-wrapper p.para-content {
  font-family: "Catamaran";
  font-size: 16px;
  color: #164f58;
}

.disclaimer-wrapper .ant-checkbox + span,
.disclaimer-submit p {
  color: rgb(27 62 105);
  font-size: 14px;
  margin-bottom: 10px;
}

.disclaimer-wrapper .ant-checkbox + span {
  margin-left: 15px;
}

.disclaimer-wrapper .ant-checkbox-checked .ant-checkbox-inner {
  background: #22c55e;
  border: #22c55e;
}

.disclaimer-wrapper .button-content {
  height: 61px;
  width: 220px;
  background: #22c55e 0 0 no-repeat padding-box;
  border-radius: 8px;
  text-align: center;
  font: normal normal 700 18px/30px Catamaran;
  letter-spacing: 0;
  color: #fff;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  align-self: flex-end;
}

.disclaimer-wrapper .button-content:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.disclaimer-wrapper .button-content span {
  font-size: 11px;
  margin-left: 10px;
  display: inline-block;
  vertical-align: bottom;
}

.disclaimer-submit {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.disclaimer-submit p:first-child {
  font-size: 18px;
}

.disclaimer-submit a {
  color: rgb(27 62 105) !important;
  font-weight: 600;
  text-decoration: underline !important;
}

.disclaimer-wrapper .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}

/*Terms and conditions*/
.terms-container {
  margin-top: 80px;
}
.terms-container .main-heading {
  text-align: center;
  text-decoration: underline;
  margin-bottom: 20px;
}

.terms-container ul li {
  list-style-type: disc;
  padding-top: 10px;
}

.terms-container ol {
  margin: 10px 0;
  list-style-type: none;
  counter-reset: item;
  padding: 0;
}

.terms-container ol > li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}

.terms-container ol > li:before {
  content: counters(item, ".") ". ";
  display: table-cell;
  padding-right: 0.6em;
}

.terms-container li ol > li {
  margin: 0 0 8px;
}

.terms-container li ol > li:before {
  content: counters(item, ".") " ";
}

.active .validation-required {
  color: #f1bd2e;
}

.validation-required {
  color: #f12e2e;
}

.assets-card-dropdown .label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.assets-card-dropdown .option-text {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 10px;
}

.assets-card-dropdown .validation {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 10px;
  text-align: right;
  margin-top: 10px;
}

.create-vault-container .right-block-wrap .vault-type-group {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.create-vault-container .right-block-wrap .vault-type-group .vault-type-radio {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #164f58;
}
.create-vault-container .active .vault-type-group .vault-type-radio {
  color: #ffffff;
}
.create-vault-container
  .vault-type-group
  .vault-type-radio
  .ant-radio-checked
  .ant-radio-inner {
  border-color: #22c55e;
}
.create-vault-container
  .vault-type-group
  .vault-type-radio
  .ant-radio-inner::after {
  background-color: #22c55e;
}
.create-vault-container
  .active
  .vault-type-group
  .vault-type-radio
  .ant-radio-inner::after {
  background-color: #22c55e;
}

.vaultwarpBox .time-locked {
  text-align: left;
}

.add-long-button {
  color: #22c55e;
  cursor: pointer;
}
.active .add-long-button {
  color: #22c55e;
}

