.graph-wrapper {
  background: white;
  border: 2px solid #d1d1d1;
  border-radius: 8px;
  display: inline-block;
  width: 57vw;
  height: 42rem;
}

.chart-heading-and-dropdown {
  padding: 15px 35px 0px 35px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.performance-text {
  display: flex;
  color: #164F58;
  font-family: Catamaran;
  font-size: 24px;
  align-items: center;
}

.dashboard-chart-resizer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 40vh;
  /* width: 64.3125vw; */
  background-color: white;
  border-radius: 8px;
  box-shadow: 4px 4px 30px rgba(27, 62, 105, 0.16);
}

.dashboard-chart-resizer .raphael-group-YhixcaBd {
  display: none;
}

.chart-top-contents-aligner {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* gap: 2vw; */
  margin-top: 15px;
  margin-bottom: 10px;
}

.mini-flex {
  display: flex;
}

span .recharts-legend-item-text {
  padding-top: 30px;
}

.dashboard-chart-resizer tspan {
  fill: #6d7681;
  letter-spacing: 0.03em;

  font-size: 14px;
}

.chart-paginate-styler {
  display: flex;
  gap: 5px;
  border: 1px solid #B1ADC3;
  border-radius: 3px;
  padding: 10px 5px;
}

.chart-paginate-styler button {
  background: none;
  border: none;
  cursor: pointer;
  font-weight: bold;
  /* color:#9B97B3; */
}

.graph-brush-styler {
  background: #c42121;
}

@media only screen and (max-width: 1640px) {
  .performance-text {
    font-size: 20px;
  }
  .dashboard-chart-resizer tspan {
    font-size: 12px;
  }
}
