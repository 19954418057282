@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');


.tooltip {
    position: relative;
    display: inline-block;
  }
.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #DEE5EF;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
  
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

  .tooltip .tooltiptext  p{
    font-size: 10px;
    font-weight: bold;
    color: #0E2139;
    font-family: Catamaran;
  }
