#connectToButton:hover {
  background-color: white;
}

.headerIconWrap {
  width: 65%;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.connect-wallet-wrapper {
  width: fit-content;
  align-items: center;
  margin-right: 45px;
}

.connect-wallet-wrapper .connect-wallet-button {
  /* position: absolute;
  right: 45px; */
  width: 160px;
  font-size: 18px;
  font-weight: 700;
  font-family: "Catamaran", sans-serif;
  color: #164F58;
  border: 1px solid #164F58;
  background: none;
  cursor: pointer;
  padding: 2px;
  border-radius: 8px;
}

.connect-wallet-wrapper .connect-wallet-button:hover {
  background-color: rgb(250, 250, 250);
}

.connect-wallet-options {
  /* width: 120px; */
  font-size: 16px;
  font-weight: 700;
  font-family: "Catamaran", sans-serif;
  color: #1b3e69;
  border: 0px;
  background: none;
  cursor: pointer;
  padding: 2px;
  border-radius: 8px;
}

.connect-wallet-dropdown { 
  z-index: 1101 !important;
}