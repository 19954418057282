.assets-debts-table-styler {
  background-color: white;
  width: 100%;
  border-radius: 8px;
  padding: 20px 20px;
  min-height: 520px;
  box-shadow: 4px 4px 30px rgba(27, 62, 105, 0.16);
}

.asset-details-search-elements {
  display: flex;
  flex-direction: row;
  gap: 5%;

  justify-content: flex-start;
  width: 75%;
  /* margin-bottom:30px; */
}

/* #0E2139 */

.asset-debts-table-first-flex {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
}
.asset-details-table-button-styling {
  background-color: #164F58;
  border-radius: 8px;
  border: 0px;
  transition: 0.3s all ease;
  padding: 8px 20px 8px 20px;
}

.asset-details-table-button-styling.disabled-button {
  cursor: not-allowed !important;
  background-color: #164F58;
}

#reset-button {
  background-color: #dee5ef;
}

#reset-button:hover {
  background-color: #154c54;
}
#reset-button p {
  color: #164F58;
}
#reset-button:hover p {
  color: white;
}

.asset-details-table-button-styling:hover {
  background: #dee5ef;
  transition: 0.3s all ease;
  cursor: pointer;
}

.asset-details-table-button-styling p {
  font-size: 14px;
  font-weight: bold;
  transition: 0.3s all ease;
  color: white;
}

.asset-details-table-button-styling:hover p {
  font-size: 14px;
  font-weight: bold;
  transition: 0.3s all ease;
  color: #1b3e69;
}

.assets-debts-row-two-display-flexer {
  display: flex;
  justify-content: flex-start;
  gap: 30px;
  margin-top: 5px;
}
a:link {
  /* color: green; */
  background-color: transparent;
  text-decoration: none;
}
a:visited {
  color: #3f51b5;
  background-color: transparent;
  text-decoration: none;
}
a:hover {
  color: rgb(71, 153, 207);
  background-color: transparent;
  text-decoration: underline;
}

a:active {
  color: yellow;
  background-color: transparent;
  text-decoration: underline;
}

img {
  height: 15px;
}

.asset-page-aligner {
  padding: 1% 0px 0px 30px;
  width: 65.91vw;
}

p.coins {
  color: #164F58;
  font-family: Catamaran;
  font-size: 36px;
}

.asset-details-internal {
  display: flex;
}

.asset-details-internal p.manage-list-beautify {
  font-size: 20px;
  padding-left: 10vh;
}

div.asset-bold {
  font-weight: bold;
}

.network-lister {
  align-items: center;

  background-color: rgb(238, 111, 27);
  color: white;
  height: 50px;
  /* width:110.8%;
  margin-left: -32px; */
}

@media only screen and (max-width: 1380px) {
  .asset-page-aligner {
    padding: 1% 0px 0px 15px;
  }
}

@media only screen and (max-width: 1640px) {
  p.coins {
    font-size: 30px;
  }
}

/**/
/* @media only screen and (max-width: 1560px) {
  .investments_and_vault_stats {
    width: 285px;
  }
}

@media only screen and (max-width: 1280px) {
  .investments_and_vault_stats {
  width:295px;
  }
} */

@media only screen and (max-width: 1720px) {
  .asset-page-aligner {
    width: 63.91vw;
  }
}

@media only screen and (max-width: 1618px) {
  .asset-page-aligner {
    width: 61.91vw;
  }
}

@media only screen and (max-width: 1516px) {
  .asset-page-aligner {
    width: 59.91vw;
  }

  .investments_and_vault_stats {
    width: 265px;
  }
}

@media only screen and (max-width: 1446px) {
  .asset-page-aligner {
    width: 57.91vw;
  }
  .investments_and_vault_stats {
    width: 265px;
  }
}

@media only screen and (max-width: 1380px) {
  .asset-page-aligner {
    width: 55vw;
    padding-left: 15px;
  }
}

@media only screen and (max-width: 1279px) {
  .asset-page-aligner {
    width: 69vw;
  }
  .investments_and_vault_stats {
    width: 295px;
  }
}

@media only screen and (max-width: 1150px) {
  .asset-page-aligner {
    width: 66vw;
  }
}
