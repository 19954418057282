.new-percentage-card{
    display:flex;
    margin-top: 3px;
    margin-bottom:-10px;
    width:80%;
    justify-content: flex-start;
    height:22px;
    
}

.small-info-graph{
    height:15px;
}

/* @media only screen and (max-width: 1700px){
    .new-percentage-card{
        margin-top:3px;
    }
} */


/* #grow{
    background:#2BB160;
}

#drop{
    background:#00BFF5;
}

#null{
    background:rgb(11, 113, 230);
} */
/* green   #2BB160 */

/* #C42121    #00BFF5 */