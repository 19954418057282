.description-styler {
  padding-top: 40px;
  width: 65vw;
}

@media only screen and (max-width: 1560px) {
  .description-styler {
    width: 70vw;
  }
}

@media only screen and (max-width: 1270px) {
  .description-styler {
    width: 73vw;
  }
}

.vault-description-title {
  font-size: 14px;
  font-weight: bold;
}

.description-card {
  min-width: fit-content;
  max-width: 460.72px;
  padding: 7px;
  padding-left: 20px;
  height: 85px;
  background: #ffffff;
  box-shadow: 4px 4px 20px rgba(27, 62, 105, 0.12);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-bottom: 1rem;
  margin-left: 2vw;
}

.description-card .description-title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 149.68%;
  color: #484d6a;
  margin-bottom: 5px;
}

.description-card .description-content {
  display: grid;
  grid-template-columns: 0.2fr 5fr 1fr;
  overflow: hidden;
}

.description-card .description-content .icon {
  background-image: url(../../../../assets/icons/description.svg);
  background-repeat: no-repeat;
  width: 20px;
}

.description-card .description-content .description-text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #1b3e69;
  height: 32px;
  overflow: hidden;
}

.description-card .description-content .read-more {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #b47c39;
  cursor: pointer;
  display: flex;
  align-items: flex-end;
}

.complete-card {
  width: 460.72px;
  max-height: 152.27px;
  height: fit-content;
  background: #f3f6f9;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.complete-card .title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #1b3e69;
  margin-bottom: 5px;
}

.complete-card .description-text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #1b3e69;
}

.long-description {
  padding: 1% 3% 0px 30px;
  width: 60vw;
  display: flex;
  flex-direction: column;
}

.long-description .long-description-title {
  font-family: Catamaran;
  color: #1b3e69;
  font-size: 30px;
  margin: 15px 0px;
}

.long-description .long-description-content {
  display: grid;
  grid-template-columns: auto;
  width: 100%;
  border-radius: 8px;
  padding: 20px 20px;
  min-height: 520px;
  box-shadow: 4px 4px 30px rgb(27 62 105 / 16%);
}

.complete-card .break-line {
  margin: 10px 0px;
  height: 0.25px;
  background-color: #1b3e69;
  width: 100%;
}

.complete-card .read-more-here {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #b47c39;
}
