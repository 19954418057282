@import "~antd/dist/antd.css";

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

.settings-button {
  height: inherit;
  margin-bottom: 50%;
}

.my-modal .title {
  color: #1b3e69;
}

.my-modal .logo {
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
}

.my-modal .ant-modal-content {
  border-radius: 8px;
  font: normal normal normal 10px/14px Catamaran;
}

.my-modal .ant-modal-header {
  border-radius: 8px 8px 0 0;
  background-color: #1b3e69;
  color: white;
}

.my-modal .ant-modal-title {
  color: white;
  background-color: #1b3e69;
  font-family: Catamaran, sans-serif;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
}

.my-modal .ant-modal-close {
  color: white;
}

.my-modal .ant-modal-body {
  display: grid;
  grid-template-rows: auto;
  gap: 20px;
  justify-self: center;
}

.my-modal .ant-divider-horizontal {
  margin: 0;
}

.my-modal .ant-switch-checked {
  background-color: #1b3e69;
}

.my-modal .ant-col-12,
.my-modal .ant-col-10,
.my-modal .ant-col-11 {
  color: #525252;
  font-weight: bold;
  font-family: Catamaran, sans-serif;
}

.my-modal .ant-col-12 p,
.my-modal .ant-col-10 p,
.my-modal .ant-col-11 p {
  color: #797979;
  font-weight: 400;
}

.my-modal .ant-col-14 span,
.my-modal .ant-col-12 span {
  color: #1b3e69;
}

.my-modal .ant-modal-footer {
  display: flex;
  justify-content: flex-end;
}

.ant-form-item-label>label::after {
  content: "";
}

.my-modal .input-values {
  display: grid;
  grid-auto-flow: column;
}

.my-modal .button-save {
  border-radius: 8px;
  border: none;
  box-shadow: none;
  background-color: #b47c39;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
}

.my-modal .dropdown-button {
  display: flex;
  justify-content: space-between;
}

.my-modal .dropdown-text {
  font-size: 14px;
  font-weight: bold;
  color: #1b3e69;
}

.my-modal .sub-text {
  font-size: 14px;
  text-align: justify;
}

.my-modal .ant-input {
  border-radius: 4px;
  color: #1b3e69;
}

.my-modal .input-text-right {
  text-align: right;
  margin-bottom: 10px;
  font-weight: 800;
}

.my-modal .input-below {
  background-color: #dee5ef;
  color: #525252;
  border-radius: 4px;
}

.add-new-field {
  width: 100%;
  color: #b47c39;
  border-color: #b47c39;
}

.add-new-field:active,
.add-new-field:focus {
  width: 100%;
  color: #b47c39;
  border-color: #b47c39;
}

.core-settings Input {
  height: 42px;
}

.core-settings .delete-custom-input {
  position: absolute;
  height: 24.73px;
  width: 24.73px;
  min-width: 24px;
  bottom: 24.73px;
  right: -5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.core-settings .ant-select-selection-item {
  border-radius: 12px;
  height: 30px;
  min-width: 60px;
  background: #66ddff;
}

.core-settings .single-select .ant-select-selection-item {
  background: transparent;
}

.my-modal .core-settings .ant-select-selection-item-content,
.my-modal .core-settings .anticon-close {
  color: #ffffff;
}